import React from 'react';
import { Router, Route, Switch, Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";
import AOS from 'aos'
import home from "../Page/home";
import Footer from "../Page/Footer";
import Header from "../Page/Header";
import Common from "../Page/Common";
import { history } from '../_helpers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../assets/vendor/aos/aos.css"
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";
import "../assets/css/slide.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

import AboutMe from "../Page/Pages/AboutMe";
import Faqs from "../Page/Pages/Faqs";
import Portfolio from "../Page/Pages/Portfolio";
import Features from "../Page/Pages/Features";
import Pricing from "../Page/Pages/Pricing";
import Services from "../Page/Pages/Services";
import Certificate from "../Page/Pages/Certificate";
import Contact from "../Page/Pages/Contact";
import ThankYou from "../Page/Pages/ThankYou";
import WebDesignerInGurgaon from "../Page/Services/WebDesignerInGurgaon";
import WebDeveloperInGurgaon from "../Page/Services/WebDeveloperInGurgaon";
import GraphicDesignerinGurgaon from "../Page/Services/GraphicDesignerinGurgaon";
import FreelanceSeoServicesInGurugram from "../Page/Services/FreelanceSeoServicesInGurugram";
import PPCServicesInGurgaon from "../Page/Services/PPCServicesInGurgaon";
import FreelanceJobsInGurgaon from "../Page/Services/FreelanceJobsInGurgaon";
import FreelanceWorkInGurgaon from "../Page/Services/FreelanceWorkInGurgaon";
import SocialMediaMarketingFreelancersInGurugram from "../Page/Services/SocialMediaMarketingFreelancersInGurugram";
import DigitalMarketingFreelancerInBangalore from "../Page/Services/DigitalMarketingFreelancerInBangalore";
import WebsiteDesigningCompanyInBhabua from "../Page/Services/WebsiteDesigningCompanyInBhabua";
import WebsiteDesignerInBhabua from "../Page/Services/WebsiteDesignerInBhabua";
import WebsiteDesignerInBangalore from "../Page/Services/WebsiteDesignerInBangalore";
import WebsiteDesignerInLucknow from "../Page/Services/WebsiteDesignerInLucknow";
import WebsiteDesignerInAhmedabad from "../Page/Services/WebsiteDesignerInAhmedabad";
import WebsiteDesignerInHyderabad from "../Page/Services/WebsiteDesignerInHyderabad";
import WebsiteDesignerInMumbai from "../Page/Services/WebsiteDesignerInMumbai";
import WebsiteDesignerInAgra from "../Page/Services/WebsiteDesignerInAgra";
import WebsiteDesignerInChennai from "../Page/Services/WebsiteDesignerInChennai";
import WebsiteDesignerInDelhi from "../Page/Services/WebsiteDesignerInDelhi";
import WebsiteDesignerInJaipur from "../Page/Services/WebsiteDesignerInJaipur";
import WebsiteDesignerInKanpur from "../Page/Services/WebsiteDesignerInKanpur";
import WebsiteDesignerInPune from "../Page/Services/WebsiteDesignerInPune";
import WebsiteDesignerInAmritsar from "../Page/Services/WebsiteDesignerInAmritsar";
import WebsiteDesignerInKolkata from "../Page/Services/WebsiteDesignerInKolkata";
import WebsiteDesignerInNagpur from "../Page/Services/WebsiteDesignerInNagpur";
import WebsiteDesignerInGhaziabad from "../Page/Services/WebsiteDesignerInGhaziabad";
import WebsiteDesignerInSurat from "../Page/Services/WebsiteDesignerInSurat";
import WebsiteDesignerInVisakhapatnam from "../Page/Services/WebsiteDesignerInVisakhapatnam";
import WebsiteDesignerInFaridabad from "../Page/Services/WebsiteDesignerInFaridabad";
import WebsiteDesignerInCoimbatore from "../Page/Services/WebsiteDesignerInCoimbatore";

import WebsiteDesignerInIndore from "../Page/Services/WebsiteDesignerInIndore";
import WebsiteDesignerInThane from "../Page/Services/WebsiteDesignerInThane";
import WebsiteDesignerInBhopal from "../Page/Services/WebsiteDesignerInBhopal";
import WebsiteDesignerInPimpriChinchwad from "../Page/Services/WebsiteDesignerInPimpriChinchwad";
import WebsiteDesignerInPatna from "../Page/Services/WebsiteDesignerInPatna";
import WebsiteDesignerInVadodara from "../Page/Services/WebsiteDesignerInVadodara";
import WebsiteDesignerInLudhiana from "../Page/Services/WebsiteDesignerInLudhiana";
import WebsiteDesignerInNashik from "../Page/Services/WebsiteDesignerInNashik";
import WebsiteDesignerInMeerut from "../Page/Services/WebsiteDesignerInMeerut";
import WebsiteDesignerInRajkot from "../Page/Services/WebsiteDesignerInRajkot";
import WebsiteDesignerInKalyanDombivali from "../Page/Services/WebsiteDesignerInKalyanDombivali";
import WebsiteDesignerInVasaiVirar from "../Page/Services/WebsiteDesignerInVasaiVirar";
import WebsiteDesignerInVaranasi from "../Page/Services/WebsiteDesignerInVaranasi";
import WebsiteDesignerInSrinagar from "../Page/Services/WebsiteDesignerInSrinagar";
import WebsiteDesignerInAurangabad from "../Page/Services/WebsiteDesignerInAurangabad";
import WebsiteDesignerInDhanbad from "../Page/Services/WebsiteDesignerInDhanbad";
import WebsiteDesignerInNaviMumbai from "../Page/Services/WebsiteDesignerInNaviMumbai";
import WebsiteDesignerInPrayagraj from "../Page/Services/WebsiteDesignerInPrayagraj";
import WebsiteDesignerInHowrah from "../Page/Services/WebsiteDesignerInHowrah";
import WebsiteDesignerInRanchi from "../Page/Services/WebsiteDesignerInRanchi";
import WebsiteDesignerInJabalpur from "../Page/Services/WebsiteDesignerInJabalpur";
import WebsiteDesignerInGwalior from "../Page/Services/WebsiteDesignerInGwalior";
import WebsiteDesignerInVijayawada from "../Page/Services/WebsiteDesignerInVijayawada";
import WebsiteDesignerInJodhpur from "../Page/Services/WebsiteDesignerInJodhpur";
import WebsiteDesignerInMadurai from "../Page/Services/WebsiteDesignerInMadurai";
import WebsiteDesignerInRaipur from "../Page/Services/WebsiteDesignerInRaipur";
import WebsiteDesignerInKota from "../Page/Services/WebsiteDesignerInKota";

import WebsiteDesignerInGuwahati from "../Page/Services/WebsiteDesignerInGuwahati";
import WebsiteDesignerInChandigarh from "../Page/Services/WebsiteDesignerInChandigarh";
import WebsiteDesignerInSolapur from "../Page/Services/WebsiteDesignerInSolapur";
import WebsiteDesignerInHubballiDharwad from "../Page/Services/WebsiteDesignerInHubballiDharwad";
import WebsiteDesignerInTiruchirappalli from "../Page/Services/WebsiteDesignerInTiruchirappalli";
import WebsiteDesignerInTiruppur from "../Page/Services/WebsiteDesignerInTiruppur";
import WebsiteDesignerInMoradabad from "../Page/Services/WebsiteDesignerInMoradabad";
import WebsiteDesignerInMysore from "../Page/Services/WebsiteDesignerInMysore";
import WebsiteDesignerInBareilly from "../Page/Services/WebsiteDesignerInBareilly";
import WebsiteDesignerInGurgaon from "../Page/Services/WebsiteDesignerInGurgaon";
import WebsiteDesignerInAligarh from "../Page/Services/WebsiteDesignerInAligarh";
import WebsiteDesignerInJalandhar from "../Page/Services/WebsiteDesignerInJalandhar";
import WebsiteDesignerInBhubaneswar from "../Page/Services/WebsiteDesignerInBhubaneswar";
import WebsiteDesignerInSalem from "../Page/Services/WebsiteDesignerInSalem";
import WebsiteDesignerInMiraBhayandar from "../Page/Services/WebsiteDesignerInMiraBhayandar";
import WebsiteDesignerInWarangal from "../Page/Services/WebsiteDesignerInWarangal";
import WebsiteDesignerInThiruvananthapuram from "../Page/Services/WebsiteDesignerInThiruvananthapuram";
import WebsiteDesignerInGuntur from "../Page/Services/WebsiteDesignerInGuntur";
import WebsiteDesignerInBhiwandi from "../Page/Services/WebsiteDesignerInBhiwandi";
import WebsiteDesignerInSaharanpur from "../Page/Services/WebsiteDesignerInSaharanpur";
import WebsiteDesignerInGorakhpur from "../Page/Services/WebsiteDesignerInGorakhpur";
import WebsiteDesignerInBikaner from "../Page/Services/WebsiteDesignerInBikaner";
import WebsiteDesignerInAmravati from "../Page/Services/WebsiteDesignerInAmravati";
import WebsiteDesignerInNoida from "../Page/Services/WebsiteDesignerInNoida";
import WebsiteDesignerInJamshedpur from "../Page/Services/WebsiteDesignerInJamshedpur";
import WebsiteDesignerInBhilai from "../Page/Services/WebsiteDesignerInBhilai";
import WebsiteDesignerInCuttack from "../Page/Services/WebsiteDesignerInCuttack";
import WebsiteDesignerInFirozabad from "../Page/Services/WebsiteDesignerInFirozabad";
import WebsiteDesignerInKochi from "../Page/Services/WebsiteDesignerInKochi";
import WebsiteDesignerInNellore from "../Page/Services/WebsiteDesignerInNellore";
import WebsiteDesignerInBhavnagar from "../Page/Services/WebsiteDesignerInBhavnagar";
import WebsiteDesignerInDehradun from "../Page/Services/WebsiteDesignerInDehradun";
import WebsiteDesignerInDurgapur from "../Page/Services/WebsiteDesignerInDurgapur";
import WebsiteDesignerInAsansol from "../Page/Services/WebsiteDesignerInAsansol";
import WebsiteDesignerInRourkela from "../Page/Services/WebsiteDesignerInRourkela";
import WebsiteDesignerInNanded from "../Page/Services/WebsiteDesignerInNanded";
import WebsiteDesignerInKolhapur from "../Page/Services/WebsiteDesignerInKolhapur";
import WebsiteDesignerInAjmer from "../Page/Services/WebsiteDesignerInAjmer";
import WebsiteDesignerInAkola from "../Page/Services/WebsiteDesignerInAkola";
import WebsiteDesignerInGulbarga from "../Page/Services/WebsiteDesignerInGulbarga";
import WebsiteDesignerInJamnagar from "../Page/Services/WebsiteDesignerInJamnagar";
import WebsiteDesignerInUjjain from "../Page/Services/WebsiteDesignerInUjjain";
import WebsiteDesignerInLoni from "../Page/Services/WebsiteDesignerInLoni";
import WebsiteDesignerInSiliguri from "../Page/Services/WebsiteDesignerInSiliguri";
import WebsiteDesignerInJhansi from "../Page/Services/WebsiteDesignerInJhansi";
import WebsiteDesignerInUlhasnagar from "../Page/Services/WebsiteDesignerInUlhasnagar";
import WebsiteDesignerInJammu from "../Page/Services/WebsiteDesignerInJammu";
import WebsiteDesignerInSangliMirajKupwad from "../Page/Services/WebsiteDesignerInSangliMirajKupwad";
import WebsiteDesignerInMangalore from "../Page/Services/WebsiteDesignerInMangalore";
import WebsiteDesignerInErode from "../Page/Services/WebsiteDesignerInErode";
import WebsiteDesignerInBelgaum from "../Page/Services/WebsiteDesignerInBelgaum";
import WebsiteDesignerInAmbattur from "../Page/Services/WebsiteDesignerInAmbattur";
import WebsiteDesignerInTirunelveli from "../Page/Services/WebsiteDesignerInTirunelveli";
import WebsiteDesignerInMalegaon from "../Page/Services/WebsiteDesignerInMalegaon";
import WebsiteDesignerInGaya from "../Page/Services/WebsiteDesignerInGaya";
import WebsiteDesignerInJalgaon from "../Page/Services/WebsiteDesignerInJalgaon";
import WebsiteDesignerInUdaipur from "../Page/Services/WebsiteDesignerInUdaipur";
import WebsiteDesignerInMaheshtala from "../Page/Services/WebsiteDesignerInMaheshtala";
import WebsiteDesignerInDavanagere from "../Page/Services/WebsiteDesignerInDavanagere";
import WebsiteDesignerInKozhikode from "../Page/Services/WebsiteDesignerInKozhikode";
import WebsiteDesignerInKurnool from "../Page/Services/WebsiteDesignerInKurnool";
import WebsiteDesignerInRajpurSonarpur from "../Page/Services/WebsiteDesignerInRajpurSonarpur";
import WebsiteDesignerInRajahmundry from "../Page/Services/WebsiteDesignerInRajahmundry";
import WebsiteDesignerInBokaro from "../Page/Services/WebsiteDesignerInBokaro";
import WebsiteDesignerInSouthDumdum from "../Page/Services/WebsiteDesignerInSouthDumdum";
import WebsiteDesignerInBellary from "../Page/Services/WebsiteDesignerInBellary";
import WebsiteDesignerInPatiala from "../Page/Services/WebsiteDesignerInPatiala";
import WebsiteDesignerInGopalpur from "../Page/Services/WebsiteDesignerInGopalpur";
import WebsiteDesignerInAgartala from "../Page/Services/WebsiteDesignerInAgartala";
import WebsiteDesignerInBhagalpur from "../Page/Services/WebsiteDesignerInBhagalpur";
import WebsiteDesignerInMuzaffarnagar from "../Page/Services/WebsiteDesignerInMuzaffarnagar";
import WebsiteDesignerInBhatpara from "../Page/Services/WebsiteDesignerInBhatpara";
import WebsiteDesignerInPanihati from "../Page/Services/WebsiteDesignerInPanihati";
import WebsiteDesignerInLatur from "../Page/Services/WebsiteDesignerInLatur";
import WebsiteDesignerInDhule from "../Page/Services/WebsiteDesignerInDhule";
import WebsiteDesignerInTirupati from "../Page/Services/WebsiteDesignerInTirupati";
import WebsiteDesignerInRohtak from "../Page/Services/WebsiteDesignerInRohtak";
import WebsiteDesignerInKorba from "../Page/Services/WebsiteDesignerInKorba";
import WebsiteDesignerInBhilwara from "../Page/Services/WebsiteDesignerInBhilwara";
import WebsiteDesignerInBerhampur from "../Page/Services/WebsiteDesignerInBerhampur";
import WebsiteDesignerInMuzaffarpur from "../Page/Services/WebsiteDesignerInMuzaffarpur";
import WebsiteDesignerInAhmednagar from "../Page/Services/WebsiteDesignerInAhmednagar";
import WebsiteDesignerInMathura from "../Page/Services/WebsiteDesignerInMathura";
import WebsiteDesignerInKollam from "../Page/Services/WebsiteDesignerInKollam";
import WebsiteDesignerInAvadi from "../Page/Services/WebsiteDesignerInAvadi";
import WebsiteDesignerInKadapa from "../Page/Services/WebsiteDesignerInKadapa";
import WebsiteDesignerInKamarhati from "../Page/Services/WebsiteDesignerInKamarhati";
import WebsiteDesignerInSambalpur from "../Page/Services/WebsiteDesignerInSambalpur";
import WebsiteDesignerInBilaspur from "../Page/Services/WebsiteDesignerInBilaspur";
import WebsiteDesignerInShahjahanpur from "../Page/Services/WebsiteDesignerInShahjahanpur";
import WebsiteDesignerInSatara from "../Page/Services/WebsiteDesignerInSatara";
import WebsiteDesignerInBijapur from "../Page/Services/WebsiteDesignerInBijapur";
import WebsiteDesignerInRampur from "../Page/Services/WebsiteDesignerInRampur";
import WebsiteDesignerInShivamogga from "../Page/Services/WebsiteDesignerInShivamogga";
import WebsiteDesignerInChandrapur from "../Page/Services/WebsiteDesignerInChandrapur";
import WebsiteDesignerInJunagadh from "../Page/Services/WebsiteDesignerInJunagadh";
import WebsiteDesignerInThrissur from "../Page/Services/WebsiteDesignerInThrissur";
import WebsiteDesignerInAlwar from "../Page/Services/WebsiteDesignerInAlwar";
import WebsiteDesignerInBardhaman from "../Page/Services/WebsiteDesignerInBardhaman";
import WebsiteDesignerInKulti from "../Page/Services/WebsiteDesignerInKulti";
import WebsiteDesignerInKakinada from "../Page/Services/WebsiteDesignerInKakinada";
import WebsiteDesignerInNizamabad from "../Page/Services/WebsiteDesignerInNizamabad";
import WebsiteDesignerInParbhani from "../Page/Services/WebsiteDesignerInParbhani";
import WebsiteDesignerInTumkur from "../Page/Services/WebsiteDesignerInTumkur";
import WebsiteDesignerInKhammam from "../Page/Services/WebsiteDesignerInKhammam";
import WebsiteDesignerInOzhukarai from "../Page/Services/WebsiteDesignerInOzhukarai";
import WebsiteDesignerInBiharSharif from "../Page/Services/WebsiteDesignerInBiharSharif";
import WebsiteDesignerInPanipat from "../Page/Services/WebsiteDesignerInPanipat";
import WebsiteDesignerInDarbhanga from "../Page/Services/WebsiteDesignerInDarbhanga";
import WebsiteDesignerInBally from "../Page/Services/WebsiteDesignerInBally";
import WebsiteDesignerInAizawl from "../Page/Services/WebsiteDesignerInAizawl";
import WebsiteDesignerInDewas from "../Page/Services/WebsiteDesignerInDewas";
import WebsiteDesignerInIchalkaranji from "../Page/Services/WebsiteDesignerInIchalkaranji";
import WebsiteDesignerInKarnal from "../Page/Services/WebsiteDesignerInKarnal";
import WebsiteDesignerInBathinda from "../Page/Services/WebsiteDesignerInBathinda";
import WebsiteDesignerInJalna from "../Page/Services/WebsiteDesignerInJalna";
import WebsiteDesignerInEluru from "../Page/Services/WebsiteDesignerInEluru";
import WebsiteDesignerInKirariSulemanNagar from "../Page/Services/WebsiteDesignerInKirariSulemanNagar";
import WebsiteDesignerInBarasat from "../Page/Services/WebsiteDesignerInBarasat";
import WebsiteDesignerInPurnia from "../Page/Services/WebsiteDesignerInPurnia";
import WebsiteDesignerInSatna from "../Page/Services/WebsiteDesignerInSatna";
import WebsiteDesignerInMau from "../Page/Services/WebsiteDesignerInMau";
import WebsiteDesignerInSonipat from "../Page/Services/WebsiteDesignerInSonipat";
import WebsiteDesignerInFarrukhabad from "../Page/Services/WebsiteDesignerInFarrukhabad";
import WebsiteDesignerInSagar from "../Page/Services/WebsiteDesignerInSagar";
import WebsiteDesignerInDurg from "../Page/Services/WebsiteDesignerInDurg";
import WebsiteDesignerInImphal from "../Page/Services/WebsiteDesignerInImphal";
import WebsiteDesignerInRatlam from "../Page/Services/WebsiteDesignerInRatlam";
import WebsiteDesignerInHapur from "../Page/Services/WebsiteDesignerInHapur";
import WebsiteDesignerInArrah from "../Page/Services/WebsiteDesignerInArrah";
import WebsiteDesignerInKarimnagar from "../Page/Services/WebsiteDesignerInKarimnagar";
import WebsiteDesignerInAnantapur from "../Page/Services/WebsiteDesignerInAnantapur";
import WebsiteDesignerInEtawah from "../Page/Services/WebsiteDesignerInEtawah";
import WebsiteDesignerInAmbernath from "../Page/Services/WebsiteDesignerInAmbernath";
import WebsiteDesignerInNorthDumdum from "../Page/Services/WebsiteDesignerInNorthDumdum";
import WebsiteDesignerInBharatpur from "../Page/Services/WebsiteDesignerInBharatpur";
import WebsiteDesignerInBegusarai from "../Page/Services/WebsiteDesignerInBegusarai";
import WebsiteDesignerInNewDelhi from "../Page/Services/WebsiteDesignerInNewDelhi";
import WebsiteDesignerInGandhidham from "../Page/Services/WebsiteDesignerInGandhidham";
import WebsiteDesignerInBaranagar from "../Page/Services/WebsiteDesignerInBaranagar";
import WebsiteDesignerInTiruvottiyur from "../Page/Services/WebsiteDesignerInTiruvottiyur";
import WebsiteDesignerInPuducherry from "../Page/Services/WebsiteDesignerInPuducherry";
import WebsiteDesignerInSikar from "../Page/Services/WebsiteDesignerInSikar";
import WebsiteDesignerInThoothukudi from "../Page/Services/WebsiteDesignerInThoothukudi";
import WebsiteDesignerInRewa from "../Page/Services/WebsiteDesignerInRewa";
import WebsiteDesignerInMirzapur from "../Page/Services/WebsiteDesignerInMirzapur";
import WebsiteDesignerInRaichur from "../Page/Services/WebsiteDesignerInRaichur";
import WebsiteDesignerInPali from "../Page/Services/WebsiteDesignerInPali";
import WebsiteDesignerInRamagundam from "../Page/Services/WebsiteDesignerInRamagundam";
import WebsiteDesignerInHaridwar from "../Page/Services/WebsiteDesignerInHaridwar";
import WebsiteDesignerInVijayanagaram from "../Page/Services/WebsiteDesignerInVijayanagaram";
import WebsiteDesignerInKatihar from "../Page/Services/WebsiteDesignerInKatihar";
import WebsiteDesignerInNagarcoil from "../Page/Services/WebsiteDesignerInNagarcoil";
import WebsiteDesignerInSriGanganagar from "../Page/Services/WebsiteDesignerInSriGanganagar";
import WebsiteDesignerInKarawalNagar from "../Page/Services/WebsiteDesignerInKarawalNagar";
import WebsiteDesignerInMango from "../Page/Services/WebsiteDesignerInMango";
import WebsiteDesignerInThanjavur from "../Page/Services/WebsiteDesignerInThanjavur";
import WebsiteDesignerInBulandshahr from "../Page/Services/WebsiteDesignerInBulandshahr";
import WebsiteDesignerInUluberia from "../Page/Services/WebsiteDesignerInUluberia";
import WebsiteDesignerInMurwara from "../Page/Services/WebsiteDesignerInMurwara";
import WebsiteDesignerInSambhal from "../Page/Services/WebsiteDesignerInSambhal";
import WebsiteDesignerInSingrauli from "../Page/Services/WebsiteDesignerInSingrauli";
import WebsiteDesignerInNadiad from "../Page/Services/WebsiteDesignerInNadiad";
import WebsiteDesignerInSecunderabad from "../Page/Services/WebsiteDesignerInSecunderabad";
import WebsiteDesignerInNaihati from "../Page/Services/WebsiteDesignerInNaihati";
import WebsiteDesignerInYamunanagar from "../Page/Services/WebsiteDesignerInYamunanagar";
import WebsiteDesignerInBidhanNagar from "../Page/Services/WebsiteDesignerInBidhanNagar";
import WebsiteDesignerInPallavaram from "../Page/Services/WebsiteDesignerInPallavaram";
import WebsiteDesignerInBidar from "../Page/Services/WebsiteDesignerInBidar";
import WebsiteDesignerInMunger from "../Page/Services/WebsiteDesignerInMunger";
import WebsiteDesignerInPanchkula from "../Page/Services/WebsiteDesignerInPanchkula";
import WebsiteDesignerInBurhanpur from "../Page/Services/WebsiteDesignerInBurhanpur";
import WebsiteDesignerInRaurkelaIndustrialTownship from "../Page/Services/WebsiteDesignerInRaurkelaIndustrialTownship";
import WebsiteDesignerInKharagpur from "../Page/Services/WebsiteDesignerInKharagpur";
import WebsiteDesignerInDindigul from "../Page/Services/WebsiteDesignerInDindigul";
import WebsiteDesignerInGandhinagar from "../Page/Services/WebsiteDesignerInGandhinagar";
import WebsiteDesignerInHospet from "../Page/Services/WebsiteDesignerInHospet";
import WebsiteDesignerInNangloiJat from "../Page/Services/WebsiteDesignerInNangloiJat";
import WebsiteDesignerInMalda from "../Page/Services/WebsiteDesignerInMalda";
import WebsiteDesignerInOngole from "../Page/Services/WebsiteDesignerInOngole";
import WebsiteDesignerInDeoghar from "../Page/Services/WebsiteDesignerInDeoghar";
import WebsiteDesignerInChapra from "../Page/Services/WebsiteDesignerInChapra";
import WebsiteDesignerInHaldia from "../Page/Services/WebsiteDesignerInHaldia";
import WebsiteDesignerInKhandwa from "../Page/Services/WebsiteDesignerInKhandwa";
import WebsiteDesignerInNandyal from "../Page/Services/WebsiteDesignerInNandyal";
import WebsiteDesignerInChittoor from "../Page/Services/WebsiteDesignerInChittoor";
import WebsiteDesignerInMorena from "../Page/Services/WebsiteDesignerInMorena";
import WebsiteDesignerInAmroha from "../Page/Services/WebsiteDesignerInAmroha";
import WebsiteDesignerInAnand from "../Page/Services/WebsiteDesignerInAnand";
import WebsiteDesignerInBhind from "../Page/Services/WebsiteDesignerInBhind";
import WebsiteDesignerInBhalswaJahangirPur from "../Page/Services/WebsiteDesignerInBhalswaJahangirPur";
import WebsiteDesignerInMadhyamgram from "../Page/Services/WebsiteDesignerInMadhyamgram";
import WebsiteDesignerInBhiwani from "../Page/Services/WebsiteDesignerInBhiwani";
import WebsiteDesignerInNaviMumbaiPanvelRaigad from "../Page/Services/WebsiteDesignerInNaviMumbaiPanvelRaigad";
import WebsiteDesignerInBaharampur from "../Page/Services/WebsiteDesignerInBaharampur";
import WebsiteDesignerInAmbala from "../Page/Services/WebsiteDesignerInAmbala";
import WebsiteDesignerInMorvi from "../Page/Services/WebsiteDesignerInMorvi";
import WebsiteDesignerInFatehpur from "../Page/Services/WebsiteDesignerInFatehpur";
import WebsiteDesignerInRaeBareli from "../Page/Services/WebsiteDesignerInRaeBareli";
import WebsiteDesignerInKhora from "../Page/Services/WebsiteDesignerInKhora";
import WebsiteDesignerInBhusawal from "../Page/Services/WebsiteDesignerInBhusawal";
import WebsiteDesignerInOrai from "../Page/Services/WebsiteDesignerInOrai";
import WebsiteDesignerInBahraich from "../Page/Services/WebsiteDesignerInBahraich";
import WebsiteDesignerInVellore from "../Page/Services/WebsiteDesignerInVellore";
import WebsiteDesignerInMahesana from "../Page/Services/WebsiteDesignerInMahesana";
import WebsiteDesignerInRaiganj from "../Page/Services/WebsiteDesignerInRaiganj";
import WebsiteDesignerInSirsa from "../Page/Services/WebsiteDesignerInSirsa";
import WebsiteDesignerInDanapur from "../Page/Services/WebsiteDesignerInDanapur";
import WebsiteDesignerInSerampore from "../Page/Services/WebsiteDesignerInSerampore";
import WebsiteDesignerInSultanPurMajra from "../Page/Services/WebsiteDesignerInSultanPurMajra";
import WebsiteDesignerInGuna from "../Page/Services/WebsiteDesignerInGuna";
import WebsiteDesignerInJaunpur from "../Page/Services/WebsiteDesignerInJaunpur";
import WebsiteDesignerInPanvel from "../Page/Services/WebsiteDesignerInPanvel";
import WebsiteDesignerInShivpuri from "../Page/Services/WebsiteDesignerInShivpuri";
import WebsiteDesignerInSurendranagarDudhrej from "../Page/Services/WebsiteDesignerInSurendranagarDudhrej";
import WebsiteDesignerInUnnao from "../Page/Services/WebsiteDesignerInUnnao";
import WebsiteDesignerInHugliandChinsurah from "../Page/Services/WebsiteDesignerInHugliandChinsurah";
import WebsiteDesignerInAlappuzha from "../Page/Services/WebsiteDesignerInAlappuzha";
import WebsiteDesignerInKottayam from "../Page/Services/WebsiteDesignerInKottayam";
import WebsiteDesignerInMachilipatnam from "../Page/Services/WebsiteDesignerInMachilipatnam";
import WebsiteDesignerInShimla from "../Page/Services/WebsiteDesignerInShimla";
import WebsiteDesignerInAdoni from "../Page/Services/WebsiteDesignerInAdoni";
import WebsiteDesignerInTenali from "../Page/Services/WebsiteDesignerInTenali";
import WebsiteDesignerInProddatur from "../Page/Services/WebsiteDesignerInProddatur";
import WebsiteDesignerInSaharsa from "../Page/Services/WebsiteDesignerInSaharsa";
import WebsiteDesignerInHindupur from "../Page/Services/WebsiteDesignerInHindupur";
import WebsiteDesignerInSasaram from "../Page/Services/WebsiteDesignerInSasaram";
import WebsiteDesignerInHajipur from "../Page/Services/WebsiteDesignerInHajipur";
import WebsiteDesignerInBhimavaram from "../Page/Services/WebsiteDesignerInBhimavaram";
import WebsiteDesignerInDehri from "../Page/Services/WebsiteDesignerInDehri";
import WebsiteDesignerInMadanapalle from "../Page/Services/WebsiteDesignerInMadanapalle";
import WebsiteDesignerInSiwan from "../Page/Services/WebsiteDesignerInSiwan";
import WebsiteDesignerInBettiah from "../Page/Services/WebsiteDesignerInBettiah";
import WebsiteDesignerInGuntakal from "../Page/Services/WebsiteDesignerInGuntakal";
import WebsiteDesignerInSrikakulam from "../Page/Services/WebsiteDesignerInSrikakulam";
import WebsiteDesignerInMotihari from "../Page/Services/WebsiteDesignerInMotihari";
import WebsiteDesignerInDharmavaram from "../Page/Services/WebsiteDesignerInDharmavaram";
import WebsiteDesignerInGudivada from "../Page/Services/WebsiteDesignerInGudivada";
import WebsiteDesignerInNarasaraopet from "../Page/Services/WebsiteDesignerInNarasaraopet";
import WebsiteDesignerInBagaha from "../Page/Services/WebsiteDesignerInBagaha";
import WebsiteDesignerInMiryalaguda from "../Page/Services/WebsiteDesignerInMiryalaguda";
import WebsiteDesignerInTadipatri from "../Page/Services/WebsiteDesignerInTadipatri";
import WebsiteDesignerInKishanganj from "../Page/Services/WebsiteDesignerInKishanganj";
import WebsiteDesignerInKaraikudi from "../Page/Services/WebsiteDesignerInKaraikudi";
import WebsiteDesignerInSuryapet from "../Page/Services/WebsiteDesignerInSuryapet";
import WebsiteDesignerInJamalpur from "../Page/Services/WebsiteDesignerInJamalpur";
import WebsiteDesignerInKavali from "../Page/Services/WebsiteDesignerInKavali";
import WebsiteDesignerInTadepalligudem from "../Page/Services/WebsiteDesignerInTadepalligudem";
import WebsiteDesignerInAmaravati from "../Page/Services/WebsiteDesignerInAmaravati";
import WebsiteDesignerInBuxar from "../Page/Services/WebsiteDesignerInBuxar";
import WebsiteDesignerInJehanabad from "../Page/Services/WebsiteDesignerInJehanabad";



const serviceData = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    "name": "Pankaj Singh - Full Stack Website Designer",
    "image": "https://www.pankj.com/assets/img/dpnew.webp",
    "url": "https://www.pankj.com",
    "telephone": "+91-7838704822",
    "description": "I'm a Full Stack Website Designer and Developer since 2013, delivering high-quality and user-friendly websites.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "U59, DLF Phase 3",
      "addressLocality": "Gurgaon",
      "postalCode": "122002",
      "addressCountry": "IN"
    },
    "sameAs": [
      "https://www.facebook.com/pankaj171cse",
      "https://twitter.com/pankajsingh642",
      "https://www.instagram.com/pankajsingh642",
      "https://www.youtube.com/@pankj_",
      "https://www.linkedin.com/in/pankj/",
      "https://in.pinterest.com/pankajsingh642/",
      "https://www.fiverr.com/pankajsingh642",
      "https://www.freelancer.in/u/pankajsingh642",
      "https://github.com/senior-web-designer",
      "https://pankj.com/"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-7838704822",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": "English"
    },
    "serviceArea": {
      "@type": "Place",
      "name": "India"
    },
    "priceRange": "₹8000-₹90000",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "reviewCount": "1025"
    },
    "mainEntityOfPage": [
      "https://www.pankj.com/about-me",
      "https://www.pankj.com/services",
      "https://www.pankj.com/certificate",
      "https://www.pankj.com/contact"
    ]
  };

  const productData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Website Designing Services in Gurgaon",
    "description": "Professional full stack website design and development services, specializing in mobile and web interfaces since 2013.",
    "image": "https://www.pankj.com/assets/img/logo.png",
    "url": "https://www.pankj.com",
    "brand": {
      "@type": "Brand",
      "name": "Pankaj Web Designer"
    },
    "sku": "WD-Gurgaon",
    "mainEntityOfPage": "https://www.pankj.com",
    "additionalType": "https://schema.org/WebPage",
    "offers": {
      "@type": "AggregateOffer",
      "url": "https://www.pankj.com",
      "priceCurrency": "INR",
      "lowPrice": "8000",
      "highPrice": "90000",
      "offerCount": "10000",
      "availability": "https://schema.org/InStock",
      "priceValidUntil": "2025-12-31"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "reviewCount": "1025"
    },
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": "Client Reviews"
      }
    },
    "keywords": "website design, website designer in gurgaon, web development company gurgaon, responsive websites, full stack developer, website designing company in gurgaon",
    "sameAs": [
      "https://www.pankj.com/about-me",
      "https://www.pankj.com/services",
      "https://www.pankj.com/certificate"
    ]
  };


AOS.init();
function App() {

const tagManagerArgs = {
    gtmId: 'GTM-MX34QNL3'
}
TagManager.initialize(tagManagerArgs)

const TRACKING_ID = "G-974LGM0B0G";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: "/", title: "Pankaj" });


    return (
        <div>
          <Helmet>

 <script type="application/ld+json">
          {JSON.stringify(serviceData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(productData)}
        </script>
<meta name="Distribution" content="Global" />
<meta name="Robots" content="follow, index, all" />
<meta name="googlebot" content="index, follow" />
<meta http-equiv="Expires" content="never" />
<meta name="document-type" content="public" />
<meta name="revisit-after" content="daily" />
<meta name="country" content="India" />
<meta name="Language" content="English" />
<meta name='rating' content="general" />
<meta name="google-site-verification" content="v1NPNyoW9kqW8_e8pf1FrUJSlfjVKw0tdsA4cs6gJ-8" />
<meta name="author" content="pankj.com" />
<meta name="theme-color" content="#000" />
<meta property="og:type" content="website" />
<meta property="og:locale" content="en_US" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@pankj" />

</Helmet>
 
                    <Router history={history}>
               <Header />
   
                        <Switch>
<Route exact path= {["/","/home", "/index.html"]} component={home} />
<Route path="/about-me" component={AboutMe} />
<Route path="/contact" component={Contact} />
<Route path="/certificate" component={Certificate} />
<Route path="/faqs" component={Faqs} />
<Route path="/portfolios" component={Portfolio} />
<Route path="/pricing" component={Pricing} />
<Route path="/services" component={Services} />
<Route path="/features" component={Features} />
<Route path="/thank-you" component={ThankYou} />
<Route path="/web-designer-in-gurgaon" component={WebDesignerInGurgaon} />
<Route path="/web-developer-in-gurgaon" component={WebDeveloperInGurgaon} />
<Route path="/graphic-designer-in-gurgaon" component={GraphicDesignerinGurgaon} />
<Route path="/freelance-seo-services-in-gurugram" component={FreelanceSeoServicesInGurugram} />
<Route path="/ppc-services-in-gurgaon" component={PPCServicesInGurgaon} />
<Route path="/freelance-jobs-in-gurgaon" component={FreelanceJobsInGurgaon} />
<Route path="/freelance-work-in-gurgaon" component={FreelanceWorkInGurgaon} />
<Route path="/social-media-marketing-freelancers-in-gurugram" component={SocialMediaMarketingFreelancersInGurugram} />
<Route path="/digital-marketing-freelancer-in-bangalore" component={DigitalMarketingFreelancerInBangalore} />
<Route path="/website-designing-company-in-bhabua" component={WebsiteDesigningCompanyInBhabua} />
<Route path="/website-designer-in-bhabua" component={WebsiteDesignerInBhabua} />
<Route path="/website-designer-in-bangalore" component={WebsiteDesignerInBangalore} />
<Route path="/website-designer-in-lucknow" component={WebsiteDesignerInLucknow} />
<Route path="/website-designer-in-ahmedabad" component={WebsiteDesignerInAhmedabad} />
<Route path="/website-designer-in-hyderabad" component={WebsiteDesignerInHyderabad} />
<Route path="/website-designer-in-mumbai" component={WebsiteDesignerInMumbai} />
<Route path="/website-designer-in-agra" component={WebsiteDesignerInAgra} />
<Route path="/website-designer-in-chennai" component={WebsiteDesignerInChennai} />
<Route path="/website-designer-in-delhi" component={WebsiteDesignerInDelhi} />
<Route path="/website-designer-in-jaipur" component={WebsiteDesignerInJaipur} />
<Route path="/website-designer-in-kanpur" component={WebsiteDesignerInKanpur} />
<Route path="/website-designer-in-pune" component={WebsiteDesignerInPune} />
<Route path="/website-designer-in-amritsar" component={WebsiteDesignerInAmritsar} />
<Route path="/website-designer-in-kolkata" component={WebsiteDesignerInKolkata} />
<Route path="/website-designer-in-nagpur" component={WebsiteDesignerInNagpur} />
<Route path="/website-designer-in-ghaziabad" component={WebsiteDesignerInGhaziabad} />
<Route path="/website-designer-in-surat" component={WebsiteDesignerInSurat} />
<Route path="/website-designer-in-visakhapatnam" component={WebsiteDesignerInVisakhapatnam} />
<Route path="/website-designer-in-faridabad" component={WebsiteDesignerInFaridabad} />
<Route path="/website-designer-in-coimbatore" component={WebsiteDesignerInCoimbatore} />
<Route path="/website-designer-in-indore" component={WebsiteDesignerInIndore} />
<Route path="/website-designer-in-thane" component={WebsiteDesignerInThane} />
<Route path="/website-designer-in-bhopal" component={WebsiteDesignerInBhopal} />
<Route path="/website-designer-in-pimpri-chinchwad" component={WebsiteDesignerInPimpriChinchwad} />
<Route path="/website-designer-in-patna" component={WebsiteDesignerInPatna} />
<Route path="/website-designer-in-vadodara" component={WebsiteDesignerInVadodara} />
<Route path="/website-designer-in-ludhiana" component={WebsiteDesignerInLudhiana} />
<Route path="/website-designer-in-nashik" component={WebsiteDesignerInNashik} />
<Route path="/website-designer-in-meerut" component={WebsiteDesignerInMeerut} />
<Route path="/website-designer-in-rajkot" component={WebsiteDesignerInRajkot} />
<Route path="/website-designer-in-kalyan-dombivali" component={WebsiteDesignerInKalyanDombivali} />
<Route path="/website-designer-in-vasai-virar" component={WebsiteDesignerInVasaiVirar} />
<Route path="/website-designer-in-varanasi" component={WebsiteDesignerInVaranasi} />
<Route path="/website-designer-in-srinagar" component={WebsiteDesignerInSrinagar} />
<Route path="/website-designer-in-aurangabad" component={WebsiteDesignerInAurangabad} />
<Route path="/website-designer-in-dhanbad" component={WebsiteDesignerInDhanbad} />
<Route path="/website-designer-in-navi-mumbai" component={WebsiteDesignerInNaviMumbai} />
<Route path="/website-designer-in-prayagraj" component={WebsiteDesignerInPrayagraj} />
<Route path="/website-designer-in-howrah" component={WebsiteDesignerInHowrah} />
<Route path="/website-designer-in-ranchi" component={WebsiteDesignerInRanchi} />
<Route path="/website-designer-in-jabalpur" component={WebsiteDesignerInJabalpur} />
<Route path="/website-designer-in-gwalior" component={WebsiteDesignerInGwalior} />
<Route path="/website-designer-in-vijayawada" component={WebsiteDesignerInVijayawada} />
<Route path="/website-designer-in-jodhpur" component={WebsiteDesignerInJodhpur} />
<Route path="/website-designer-in-madurai" component={WebsiteDesignerInMadurai} />
<Route path="/website-designer-in-raipur" component={WebsiteDesignerInRaipur} />
<Route path="/website-designer-in-kota" component={WebsiteDesignerInKota} />
<Route path="/website-designer-in-guwahati" component={WebsiteDesignerInGuwahati} />
<Route path="/website-designer-in-chandigarh" component={WebsiteDesignerInChandigarh} />
<Route path="/website-designer-in-solapur" component={WebsiteDesignerInSolapur} />
<Route path="/website-designer-in-hubballi-dharwad" component={WebsiteDesignerInHubballiDharwad} />
<Route path="/website-designer-in-tiruchirappalli" component={WebsiteDesignerInTiruchirappalli} />
<Route path="/website-designer-in-tiruppur" component={WebsiteDesignerInTiruppur} />
<Route path="/website-designer-in-moradabad" component={WebsiteDesignerInMoradabad} />
<Route path="/website-designer-in-mysore" component={WebsiteDesignerInMysore} />
<Route path="/website-designer-in-bareilly" component={WebsiteDesignerInBareilly} />
<Route path="/website-designer-in-gurgaon" component={WebsiteDesignerInGurgaon} />
<Route path="/website-designer-in-aligarh" component={WebsiteDesignerInAligarh} />
<Route path="/website-designer-in-jalandhar" component={WebsiteDesignerInJalandhar} />
<Route path="/website-designer-in-bhubaneswar" component={WebsiteDesignerInBhubaneswar} />
<Route path="/website-designer-in-salem" component={WebsiteDesignerInSalem} />
<Route path="/website-designer-in-mira-bhayandar" component={WebsiteDesignerInMiraBhayandar} />
<Route path="/website-designer-in-warangal" component={WebsiteDesignerInWarangal} />
<Route path="/website-designer-in-thiruvananthapuram" component={WebsiteDesignerInThiruvananthapuram} />
<Route path="/website-designer-in-guntur" component={WebsiteDesignerInGuntur} />
<Route path="/website-designer-in-bhiwandi" component={WebsiteDesignerInBhiwandi} />
<Route path="/website-designer-in-saharanpur" component={WebsiteDesignerInSaharanpur} />
<Route path="/website-designer-in-gorakhpur" component={WebsiteDesignerInGorakhpur} />
<Route path="/website-designer-in-bikaner" component={WebsiteDesignerInBikaner} />
<Route path="/website-designer-in-amravati" component={WebsiteDesignerInAmravati} />
<Route path="/website-designer-in-noida" component={WebsiteDesignerInNoida} />
<Route path="/website-designer-in-jamshedpur" component={WebsiteDesignerInJamshedpur} />
<Route path="/website-designer-in-bhilai" component={WebsiteDesignerInBhilai} />
<Route path="/website-designer-in-cuttack" component={WebsiteDesignerInCuttack} />
<Route path="/website-designer-in-firozabad" component={WebsiteDesignerInFirozabad} />
<Route path="/website-designer-in-kochi" component={WebsiteDesignerInKochi} />
<Route path="/website-designer-in-nellore" component={WebsiteDesignerInNellore} />
<Route path="/website-designer-in-bhavnagar" component={WebsiteDesignerInBhavnagar} />
<Route path="/website-designer-in-dehradun" component={WebsiteDesignerInDehradun} />
<Route path="/website-designer-in-durgapur" component={WebsiteDesignerInDurgapur} />
<Route path="/website-designer-in-asansol" component={WebsiteDesignerInAsansol} />
<Route path="/website-designer-in-nanded" component={WebsiteDesignerInNanded} />
<Route path="/website-designer-in-kolhapur" component={WebsiteDesignerInKolhapur} />
<Route path="/website-designer-in-ajmer" component={WebsiteDesignerInAjmer} />
<Route path="/website-designer-in-akola" component={WebsiteDesignerInAkola} />
<Route path="/website-designer-in-gulbarga" component={WebsiteDesignerInGulbarga} />
<Route path="/website-designer-in-jamnagar" component={WebsiteDesignerInJamnagar} />
<Route path="/website-designer-in-ujjain" component={WebsiteDesignerInUjjain} />
<Route path="/website-designer-in-loni" component={WebsiteDesignerInLoni} />
<Route path="/website-designer-in-siliguri" component={WebsiteDesignerInSiliguri} />
<Route path="/website-designer-in-jhansi" component={WebsiteDesignerInJhansi} />
<Route path="/website-designer-in-ulhasnagar" component={WebsiteDesignerInUlhasnagar} />
<Route path="/website-designer-in-jammu" component={WebsiteDesignerInJammu} />
<Route path="/website-designer-in-sangli-miraj-and-kupwad" component={WebsiteDesignerInSangliMirajKupwad} />
<Route path="/website-designer-in-mangalore" component={WebsiteDesignerInMangalore} />
<Route path="/website-designer-in-erode" component={WebsiteDesignerInErode} />
<Route path="/website-designer-in-belgaum" component={WebsiteDesignerInBelgaum} />
<Route path="/website-designer-in-ambattur" component={WebsiteDesignerInAmbattur} />
<Route path="/website-designer-in-tirunelveli" component={WebsiteDesignerInTirunelveli} />
<Route path="/website-designer-in-malegaon" component={WebsiteDesignerInMalegaon} />
<Route path="/website-designer-in-gaya" component={WebsiteDesignerInGaya} />
<Route path="/website-designer-in-jalgaon" component={WebsiteDesignerInJalgaon} />
<Route path="/website-designer-in-udaipur" component={WebsiteDesignerInUdaipur} />
<Route path="/website-designer-in-maheshtala" component={WebsiteDesignerInMaheshtala} />
<Route path="/website-designer-in-davanagere" component={WebsiteDesignerInDavanagere} />
<Route path="/website-designer-in-kozhikode" component={WebsiteDesignerInKozhikode} />
<Route path="/website-designer-in-kurnool" component={WebsiteDesignerInKurnool} />
<Route path="/website-designer-in-rajpur-sonarpur" component={WebsiteDesignerInRajpurSonarpur} />
<Route path="/website-designer-in-rajahmundry" component={WebsiteDesignerInRajahmundry} />
<Route path="/website-designer-in-bokaro" component={WebsiteDesignerInBokaro} />
<Route path="/website-designer-in-south-dumdum" component={WebsiteDesignerInSouthDumdum} />
<Route path="/website-designer-in-bellary" component={WebsiteDesignerInBellary} />
<Route path="/website-designer-in-patiala" component={WebsiteDesignerInPatiala} />
<Route path="/website-designer-in-gopalpur" component={WebsiteDesignerInGopalpur} />
<Route path="/website-designer-in-agartala" component={WebsiteDesignerInAgartala} />
<Route path="/website-designer-in-bhagalpur" component={WebsiteDesignerInBhagalpur} />
<Route path="/website-designer-in-muzaffarnagar" component={WebsiteDesignerInMuzaffarnagar} />
<Route path="/website-designer-in-bhatpara" component={WebsiteDesignerInBhatpara} />
<Route path="/website-designer-in-panihati" component={WebsiteDesignerInPanihati} />
<Route path="/website-designer-in-latur" component={WebsiteDesignerInLatur} />
<Route path="/website-designer-in-dhule" component={WebsiteDesignerInDhule} />
<Route path="/website-designer-in-tirupati" component={WebsiteDesignerInTirupati} />
<Route path="/website-designer-in-rohtak" component={WebsiteDesignerInRohtak} />
<Route path="/website-designer-in-korba" component={WebsiteDesignerInKorba} />
<Route path="/website-designer-in-bhilwara" component={WebsiteDesignerInBhilwara} />
<Route path="/website-designer-in-berhampur" component={WebsiteDesignerInBerhampur} />
<Route path="/website-designer-in-muzaffarpur" component={WebsiteDesignerInMuzaffarpur} />
<Route path="/website-designer-in-ahmednagar" component={WebsiteDesignerInAhmednagar} />
<Route path="/website-designer-in-mathura" component={WebsiteDesignerInMathura} />
<Route path="/website-designer-in-kollam" component={WebsiteDesignerInKollam} />
<Route path="/website-designer-in-avadi" component={WebsiteDesignerInAvadi} />
<Route path="/website-designer-in-kadapa" component={WebsiteDesignerInKadapa} />
<Route path="/website-designer-in-kamarhati" component={WebsiteDesignerInKamarhati} />
<Route path="/website-designer-in-sambalpur" component={WebsiteDesignerInSambalpur} />
<Route path="/website-designer-in-bilaspur" component={WebsiteDesignerInBilaspur} />
<Route path="/website-designer-in-shahjahanpur" component={WebsiteDesignerInShahjahanpur} />
<Route path="/website-designer-in-satara" component={WebsiteDesignerInSatara} />
<Route path="/website-designer-in-bijapur" component={WebsiteDesignerInBijapur} />
<Route path="/website-designer-in-rampur" component={WebsiteDesignerInRampur} />
<Route path="/website-designer-in-shivamogga" component={WebsiteDesignerInShivamogga} />
<Route path="/website-designer-in-chandrapur" component={WebsiteDesignerInChandrapur} />
<Route path="/website-designer-in-junagadh" component={WebsiteDesignerInJunagadh} />
<Route path="/website-designer-in-thrissur" component={WebsiteDesignerInThrissur} />
<Route path="/website-designer-in-alwar" component={WebsiteDesignerInAlwar} />
<Route path="/website-designer-in-bardhaman" component={WebsiteDesignerInBardhaman} />
<Route path="/website-designer-in-kulti" component={WebsiteDesignerInKulti} />
<Route path="/website-designer-in-kakinada" component={WebsiteDesignerInKakinada} />
<Route path="/website-designer-in-nizamabad" component={WebsiteDesignerInNizamabad} />
<Route path="/website-designer-in-parbhani" component={WebsiteDesignerInParbhani} />
<Route path="/website-designer-in-tumkur" component={WebsiteDesignerInTumkur} />
<Route path="/website-designer-in-khammam" component={WebsiteDesignerInKhammam} />
<Route path="/website-designer-in-ozhukarai" component={WebsiteDesignerInOzhukarai} />
<Route path="/website-designer-in-bihar-sharif" component={WebsiteDesignerInBiharSharif} />
<Route path="/website-designer-in-panipat" component={WebsiteDesignerInPanipat} />
<Route path="/website-designer-in-darbhanga" component={WebsiteDesignerInDarbhanga} />
<Route path="/website-designer-in-bally" component={WebsiteDesignerInBally} />
<Route path="/website-designer-in-aizawl" component={WebsiteDesignerInAizawl} />
<Route path="/website-designer-in-dewas" component={WebsiteDesignerInDewas} />
<Route path="/website-designer-in-ichalkaranji" component={WebsiteDesignerInIchalkaranji} />
<Route path="/website-designer-in-karnal" component={WebsiteDesignerInKarnal} />
<Route path="/website-designer-in-bathinda" component={WebsiteDesignerInBathinda} />
<Route path="/website-designer-in-jalna" component={WebsiteDesignerInJalna} />
<Route path="/website-designer-in-eluru" component={WebsiteDesignerInEluru} />
<Route path="/website-designer-in-kirari-suleman-nagar" component={WebsiteDesignerInKirariSulemanNagar} />
<Route path="/website-designer-in-barasat" component={WebsiteDesignerInBarasat} />
<Route path="/website-designer-in-purnia" component={WebsiteDesignerInPurnia} />
<Route path="/website-designer-in-satna" component={WebsiteDesignerInSatna} />
<Route path="/website-designer-in-mau" component={WebsiteDesignerInMau} />
<Route path="/website-designer-in-sonipat" component={WebsiteDesignerInSonipat} />
<Route path="/website-designer-in-farrukhabad" component={WebsiteDesignerInFarrukhabad} />
<Route path="/website-designer-in-sagar" component={WebsiteDesignerInSagar} />
<Route path="/website-designer-in-rourkela" component={WebsiteDesignerInRourkela} />
<Route path="/website-designer-in-durg" component={WebsiteDesignerInDurg} />
<Route path="/website-designer-in-imphal" component={WebsiteDesignerInImphal} />
<Route path="/website-designer-in-ratlam" component={WebsiteDesignerInRatlam} />
<Route path="/website-designer-in-hapur" component={WebsiteDesignerInHapur} />
<Route path="/website-designer-in-arrah" component={WebsiteDesignerInArrah} />
<Route path="/website-designer-in-karimnagar" component={WebsiteDesignerInKarimnagar} />
<Route path="/website-designer-in-anantapur" component={WebsiteDesignerInAnantapur} />
<Route path="/website-designer-in-etawah" component={WebsiteDesignerInEtawah} />
<Route path="/website-designer-in-ambernath" component={WebsiteDesignerInAmbernath} />
<Route path="/website-designer-in-north-dumdum" component={WebsiteDesignerInNorthDumdum} />
<Route path="/website-designer-in-bharatpur" component={WebsiteDesignerInBharatpur} />
<Route path="/website-designer-in-begusarai" component={WebsiteDesignerInBegusarai} />
<Route path="/website-designer-in-new-delhi" component={WebsiteDesignerInNewDelhi} />
<Route path="/website-designer-in-gandhidham" component={WebsiteDesignerInGandhidham} />
<Route path="/website-designer-in-baranagar" component={WebsiteDesignerInBaranagar} />
<Route path="/website-designer-in-tiruvottiyur" component={WebsiteDesignerInTiruvottiyur} />
<Route path="/website-designer-in-puducherry" component={WebsiteDesignerInPuducherry} />
<Route path="/website-designer-in-sikar" component={WebsiteDesignerInSikar} />
<Route path="/website-designer-in-thoothukudi" component={WebsiteDesignerInThoothukudi} />
<Route path="/website-designer-in-rewa" component={WebsiteDesignerInRewa} />
<Route path="/website-designer-in-mirzapur" component={WebsiteDesignerInMirzapur} />
<Route path="/website-designer-in-raichur" component={WebsiteDesignerInRaichur} />
<Route path="/website-designer-in-pali" component={WebsiteDesignerInPali} />
<Route path="/website-designer-in-ramagundam" component={WebsiteDesignerInRamagundam} />
<Route path="/website-designer-in-haridwar" component={WebsiteDesignerInHaridwar} />
<Route path="/website-designer-in-vijayanagaram" component={WebsiteDesignerInVijayanagaram} />
<Route path="/website-designer-in-katihar" component={WebsiteDesignerInKatihar} />
<Route path="/website-designer-in-nagarcoil" component={WebsiteDesignerInNagarcoil} />
<Route path="/website-designer-in-sri-ganganagar" component={WebsiteDesignerInSriGanganagar} />
<Route path="/website-designer-in-karawal-nagar" component={WebsiteDesignerInKarawalNagar} />
<Route path="/website-designer-in-mango" component={WebsiteDesignerInMango} />
<Route path="/website-designer-in-thanjavur" component={WebsiteDesignerInThanjavur} />
<Route path="/website-designer-in-bulandshahr" component={WebsiteDesignerInBulandshahr} />
<Route path="/website-designer-in-uluberia" component={WebsiteDesignerInUluberia} />
<Route path="/website-designer-in-murwara" component={WebsiteDesignerInMurwara} />
<Route path="/website-designer-in-sambhal" component={WebsiteDesignerInSambhal} />
<Route path="/website-designer-in-singrauli" component={WebsiteDesignerInSingrauli} />
<Route path="/website-designer-in-nadiad" component={WebsiteDesignerInNadiad} />
<Route path="/website-designer-in-secunderabad" component={WebsiteDesignerInSecunderabad} />
<Route path="/website-designer-in-naihati" component={WebsiteDesignerInNaihati} />
<Route path="/website-designer-in-yamunanagar" component={WebsiteDesignerInYamunanagar} />
<Route path="/website-designer-in-bidhan-nagar" component={WebsiteDesignerInBidhanNagar} />
<Route path="/website-designer-in-pallavaram" component={WebsiteDesignerInPallavaram} />
<Route path="/website-designer-in-bidar" component={WebsiteDesignerInBidar} />
<Route path="/website-designer-in-munger" component={WebsiteDesignerInMunger} />
<Route path="/website-designer-in-panchkula" component={WebsiteDesignerInPanchkula} />
<Route path="/website-designer-in-burhanpur" component={WebsiteDesignerInBurhanpur} />
<Route path="/website-designer-in-raurkela-industrial-township" component={WebsiteDesignerInRaurkelaIndustrialTownship} />
<Route path="/website-designer-in-kharagpur" component={WebsiteDesignerInKharagpur} />
<Route path="/website-designer-in-dindigul" component={WebsiteDesignerInDindigul} />
<Route path="/website-designer-in-gandhinagar" component={WebsiteDesignerInGandhinagar} />
<Route path="/website-designer-in-hospet" component={WebsiteDesignerInHospet} />
<Route path="/website-designer-in-nangloi-jat" component={WebsiteDesignerInNangloiJat} />
<Route path="/website-designer-in-malda" component={WebsiteDesignerInMalda} />
<Route path="/website-designer-in-ongole" component={WebsiteDesignerInOngole} />
<Route path="/website-designer-in-deoghar" component={WebsiteDesignerInDeoghar} />
<Route path="/website-designer-in-chapra" component={WebsiteDesignerInChapra} />
<Route path="/website-designer-in-haldia" component={WebsiteDesignerInHaldia} />
<Route path="/website-designer-in-khandwa" component={WebsiteDesignerInKhandwa} />
<Route path="/website-designer-in-nandyal" component={WebsiteDesignerInNandyal} />
<Route path="/website-designer-in-chittoor" component={WebsiteDesignerInChittoor} />
<Route path="/website-designer-in-morena" component={WebsiteDesignerInMorena} />
<Route path="/website-designer-in-amroha" component={WebsiteDesignerInAmroha} />
<Route path="/website-designer-in-anand" component={WebsiteDesignerInAnand} />
<Route path="/website-designer-in-bhind" component={WebsiteDesignerInBhind} />
<Route path="/website-designer-in-bhalswa-jahangirpur" component={WebsiteDesignerInBhalswaJahangirPur} />
<Route path="/website-designer-in-madhyamgram" component={WebsiteDesignerInMadhyamgram} />
<Route path="/website-designer-in-bhiwani" component={WebsiteDesignerInBhiwani} />
<Route path="/website-designer-in-navi-mumbai-panvel-raigad" component={WebsiteDesignerInNaviMumbaiPanvelRaigad} />
<Route path="/website-designer-in-baharampur" component={WebsiteDesignerInBaharampur} />
<Route path="/website-designer-in-ambala" component={WebsiteDesignerInAmbala} />
<Route path="/website-designer-in-morvi" component={WebsiteDesignerInMorvi} />
<Route path="/website-designer-in-fatehpur" component={WebsiteDesignerInFatehpur} />
<Route path="/website-designer-in-rae-bareli" component={WebsiteDesignerInRaeBareli} />
<Route path="/website-designer-in-khora" component={WebsiteDesignerInKhora} />
<Route path="/website-designer-in-bhusawal" component={WebsiteDesignerInBhusawal} />
<Route path="/website-designer-in-orai" component={WebsiteDesignerInOrai} />
<Route path="/website-designer-in-bahraich" component={WebsiteDesignerInBahraich} />
<Route path="/website-designer-in-vellore" component={WebsiteDesignerInVellore} />
<Route path="/website-designer-in-mahesana" component={WebsiteDesignerInMahesana} />
<Route path="/website-designer-in-raiganj" component={WebsiteDesignerInRaiganj} />
<Route path="/website-designer-in-sirsa" component={WebsiteDesignerInSirsa} />
<Route path="/website-designer-in-danapur" component={WebsiteDesignerInDanapur} />
<Route path="/website-designer-in-serampore" component={WebsiteDesignerInSerampore} />
<Route path="/website-designer-in-sultan-pur-majra" component={WebsiteDesignerInSultanPurMajra} />
<Route path="/website-designer-in-guna" component={WebsiteDesignerInGuna} />
<Route path="/website-designer-in-jaunpur" component={WebsiteDesignerInJaunpur} />
<Route path="/website-designer-in-panvel" component={WebsiteDesignerInPanvel} />
<Route path="/website-designer-in-shivpuri" component={WebsiteDesignerInShivpuri} />
<Route path="/website-designer-in-surendranagar-dudhrej" component={WebsiteDesignerInSurendranagarDudhrej} />
<Route path="/website-designer-in-unnao" component={WebsiteDesignerInUnnao} />
<Route path="/website-designer-in-hugli-and-chinsurah" component={WebsiteDesignerInHugliandChinsurah} />
<Route path="/website-designer-in-alappuzha" component={WebsiteDesignerInAlappuzha} />
<Route path="/website-designer-in-kottayam" component={WebsiteDesignerInKottayam} />
<Route path="/website-designer-in-machilipatnam" component={WebsiteDesignerInMachilipatnam} />
<Route path="/website-designer-in-shimla" component={WebsiteDesignerInShimla} />
<Route path="/website-designer-in-adoni" component={WebsiteDesignerInAdoni} />
<Route path="/website-designer-in-tenali" component={WebsiteDesignerInTenali} />
<Route path="/website-designer-in-proddatur" component={WebsiteDesignerInProddatur} />
<Route path="/website-designer-in-saharsa" component={WebsiteDesignerInSaharsa} />
<Route path="/website-designer-in-hindupur" component={WebsiteDesignerInHindupur} />
<Route path="/website-designer-in-sasaram" component={WebsiteDesignerInSasaram} />
<Route path="/website-designer-in-hajipur" component={WebsiteDesignerInHajipur} />
<Route path="/website-designer-in-bhimavaram" component={WebsiteDesignerInBhimavaram} />
<Route path="/website-designer-in-dehri" component={WebsiteDesignerInDehri} />
<Route path="/website-designer-in-madanapalle" component={WebsiteDesignerInMadanapalle} />
<Route path="/website-designer-in-siwan" component={WebsiteDesignerInSiwan} />
<Route path="/website-designer-in-bettiah" component={WebsiteDesignerInBettiah} />
<Route path="/website-designer-in-guntakal" component={WebsiteDesignerInGuntakal} />
<Route path="/website-designer-in-srikakulam" component={WebsiteDesignerInSrikakulam} />
<Route path="/website-designer-in-motihari" component={WebsiteDesignerInMotihari} />
<Route path="/website-designer-in-dharmavaram" component={WebsiteDesignerInDharmavaram} />
<Route path="/website-designer-in-gudivada" component={WebsiteDesignerInGudivada} />
<Route path="/website-designer-in-narasaraopet" component={WebsiteDesignerInNarasaraopet} />
<Route path="/website-designer-in-bagaha" component={WebsiteDesignerInBagaha} />
<Route path="/website-designer-in-miryalaguda" component={WebsiteDesignerInMiryalaguda} />
<Route path="/website-designer-in-tadipatri" component={WebsiteDesignerInTadipatri} />
<Route path="/website-designer-in-kishanganj" component={WebsiteDesignerInKishanganj} />
<Route path="/website-designer-in-karaikudi" component={WebsiteDesignerInKaraikudi} />
<Route path="/website-designer-in-suryapet" component={WebsiteDesignerInSuryapet} />
<Route path="/website-designer-in-jamalpur" component={WebsiteDesignerInJamalpur} />
<Route path="/website-designer-in-kavali" component={WebsiteDesignerInKavali} />
<Route path="/website-designer-in-tadepalligudem" component={WebsiteDesignerInTadepalligudem} />
<Route path="/website-designer-in-amaravati" component={WebsiteDesignerInAmaravati} />
<Route path="/website-designer-in-buxar" component={WebsiteDesignerInBuxar} />
<Route path="/website-designer-in-jehanabad" component={WebsiteDesignerInJehanabad} />



<Redirect from="*" to="/" />
</Switch>
            
        

                   <Footer />
                   <Common />
                    </Router>

                </div> 

    );
}
export { App };
