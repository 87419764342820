import { Link } from "react-router-dom";
import React from "react";
import Slider from "react-slick";

export default function Testimonials() {
var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
    <>
     <section id="testimonials" className="testimonials">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2><i className="bi bi-yelp"></i> Testimonials</h2>
          <p>What Clients are Saying About Me</p>
        </header>

        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="200">
          <div className="swiper-wrapper">
   <Slider {...settings}>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Great work!!! The team was solid, efficient and knowledgeable. They did an amazing job on my very challenging website designing. I will be using them again. Thank you for doing such a great job!
                </p>
                <div className="profile mt-auto">
                  <h3>Vaishnavi Singh</h3>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Great experience working on my website development project with you. He was professional and creative. Their dedicated approach to the work is commendable. I got user-friendly and incredible looking website within the stipulated time. Thanks for giving my brand and my business great web presence and an added online edge
                </p>
                <div className="profile mt-auto">
                  <h3>Dev Shishodia</h3>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Amazing developing support by Pankaj. He is an excellent developer with an expertise ... Redesigned a Beautiful Website for my company. Excellent Service Skills ...
                </p>
                <div className="profile mt-auto">
                  <h3>alisha kakkar</h3>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
One of the best website and digital marketing agencies in Gurgaon. Enjoyed working with him, they go extra miles with you.  We strongly recommend them.
                </p>
                <div className="profile mt-auto">
                  <h3>er vishu</h3>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
It is a trusted company that you can rely on for as the best name for website design and development, SEO services and social media marketing. I have been connected with this company for the past 3 years and have been attaining amazing experience and I wish you all the best for your support and services.👍
                </p>
                <div className="profile mt-auto">
                  <h3>Jaideep Prakash</h3>
                </div>
              </div>
            </div>
<div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
It is the most trusted company if you are looking for the best website design and seo services. I have been working with you for the past 2 years and it's been a great working experience with them. The company has the highly professional workforce. Highly recommend..
                </p>
                <div className="profile mt-auto">
                  <h3>Rakesh Kumar</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Very professional Company and very co-operative team for resolution any issue. we strongly recommended to this Company for related any website design and software developing or other services related work.
                </p>
                <div className="profile mt-auto">
                  <h3>Mahindra Bhagat</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Good place for website designing & development work with reasonable price and also provide branding work for company. I am happy for their work.
                </p>
                <div className="profile mt-auto">
                  <h3>Vaibhav Rajput</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Awesome website design services from your end. I have e-commerce website for you. Will connect again.thank you for wonderful services.
                </p>
                <div className="profile mt-auto">
                  <h3>Ajit kumar singh</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
The best thing about pankaj is the way of explaining the each step and he provide best website. I suggest this company for the latest and trending website designs and development.
                </p>
                <div className="profile mt-auto">
                  <h3>Sumeet Sharma</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
I have seen your work, it is excellent. Your responsiveness is really appreciable.
Pankaj your Web Creations are the BEST....just keep your passion alive.
                </p>
                <div className="profile mt-auto">
                  <h3>Nikita Verma</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Extremely happy with the service provided by you. your support is knowledgeable to their work and very supportive. Overall great experience .
                </p>
                <div className="profile mt-auto">
                  <h3>Dheer singh Raj</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Creativity was the best, new and unique ideas. You will not find a better company anywhere period. His pricing is beyond competitive for the amount of work you get. Unbelievable!
                </p>
                <div className="profile mt-auto">
                  <h3>Raj Bansibala</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
The team understood my requirements clearly and delivered amazing website. I would recommend always to everyone who's looking for good quality websites and designing services.
                </p>
                <div className="profile mt-auto">
                  <h3>Joel Issar</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
It is a trusted company that you can rely on for as the best name for website design and development, SEO services and social media marketing. I have been connected with this company for the past 3 years and have been attaining amazing experience and I wish you all the best for your support and services.👍
                </p>
                <div className="profile mt-auto">
                  <h3>Jaideep Prakash</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Great design delivered by you web services for one of our projects, would highly recommend for web designing & developments with SEO services. We are delighted to find such a reliable longterm partner.
                </p>
                <div className="profile mt-auto">
                  <h3>Jitendra Kushwaha</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Extremely happy with the service provided by you. your creativity is quite knowledgeable to their work and very supportive. Overall great experience with you.
                </p>
                <div className="profile mt-auto">
                  <h3>Bhagat Singh Chouhan</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Great and innovative work , Value for money , Quality work , Attractive and smooth work . Proprietor is very hardworking and passionate
                </p>
                <div className="profile mt-auto">
                  <h3>Yash Nagpal</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
I hired them for a wordpress project / website launch. It was an easy and project - new design of website was very nice and the communication was smooth. I will be happy to continue work and I can definitely recommend.
                </p>
                <div className="profile mt-auto">
                  <h3>Dilip Kumar</h3>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Thanks Pankaj for the amazing website design.you are a good professional web designer thanks for the help and support our company with your valuable service and time and also have good technical knowledge .. Again special thanks Dear Pankaj
                </p>
                <div className="profile mt-auto">
                  <h3>Nikunj Chauhan</h3>
                </div>
              </div>
            </div>

 <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
If u r looking for a professional and creative person for ur work who just don't invest as a service provider but as a friend who gives u more feedback about everything tht gonna affect minor as well as major thing
Overall it was not just nice , it was wonderful working with them.
                </p>
                <div className="profile mt-auto">
                  <h3>Shrikkant Singhania</h3>
                </div>
              </div>
            </div>

             <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
His ability to understand and meet our requirements has been truly impressive.He have very capable and professional team. I’ve always felt a level of security when working with them.
I especially appreciate their schedule flexibility, responsiveness, and willingness to go above and beyond...!! Keep it up *Pankaj web Designer* 🤙💯                </p>
                <div className="profile mt-auto">
                  <h3>Abhisek Sharma</h3>
                </div>
              </div>
            </div>
             <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Such a Beautiful design you are providing.. I always prefer you to everyone.
I salute Ur effort and Replicable work.
                </p>
                <div className="profile mt-auto">
                  <h3>Anupam Mishra</h3>
                </div>
              </div>
            </div>
             <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
Pankaj helped me build the website I wanted. He was very helpful and shared his knowledge with me throughout the process and after the website was built. I really appreciate it. I would definitely recommend Pankaj to anyone who is looking to make a website to expand their business. Thank you so much !!
                </p>
                <div className="profile mt-auto">
                  <h3>Satish Kumar</h3>
                </div>
              </div>
            </div>
             

  </Slider>

          </div>
        </div>

      </div>

    </section>
</>
  );
}
