import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
  <title>Pankaj | Website Designing Company in Bhabua</title>
<meta name="description" content="Top website designing company in bhabua. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designing company in Bhabua, website developer in Bhabua, senior website developer, web development company in Bhabua, website maker in Bhabua, website design company, pankaj web designer, pankj, freelance website designer, website designer in Bhabua, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designing Company in Bhabua" />
<meta property="og:description" content="Top website designing company in bhabua. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designing Company in Bhabua" />
<meta property="og:url" content="https://www.pankj.com/website-designing-company-in-bhabua"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designing Company in Bhabua" />
<meta name="twitter:description" content="Top website designing company in bhabua. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designing-company-in-bhabua" />

        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
        <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
 <h1 className="multiple_effects fzsml" id="text3d">Website Designing Company in Bhabua</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designing Company in Bhabua" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Premier Website Designing Company in Bhabua</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">

With over a decade of experience in Full Stack website development, Pankaj is a trusted choice for businesses in Bhabua and beyond. We specialize in creating visually stunning, highly flexible, and SEO-optimized websites that help our clients rank easily on Google and attract more customers. Whether you're a small business or an established company, our team is here to bring your vision to life.

<strong>Our Services:</strong>
<ul>
  <li><strong>Responsive Website Design</strong></li>
  <li><strong>SEO-Friendly Development</strong></li>
  <li><strong>User Experience &amp; Interface Design</strong></li>
  <li><strong>E-commerce and CMS Solutions</strong></li>
  <li><strong>Custom Web Applications</strong></li>
</ul>
We deliver quality work on time, and our clients' positive feedback speaks to our dedication and expertise. Choose Pankaj for unbeatable website design services that elevate your brand.

<strong>Get in Touch:</strong>
Contact us to discuss your next project!
<h3><strong>Pankaj - Services</strong></h3>
At <strong>Pankaj</strong>, we provide a comprehensive range of web development and design services tailored to help businesses succeed in the digital world. We combine innovation, functionality, and creativity to deliver solutions that not only look good but also perform exceptionally well. Here’s what we bring to the table:


<h4><strong>1. Custom Website Design and Development</strong></h4>
<ul>
  <li><strong>Unique, Custom Designs</strong>
We understand that each business is different, so we create fully custom designs that reflect your brand identity, values, and goals.</li>
  <li><strong>Responsive and Mobile-Friendly</strong>
Every website we build is optimized for all devices, ensuring your visitors have a seamless experience on smartphones, tablets, and desktops alike.</li>
  <li><strong>Full Stack Development Expertise</strong>
With years of expertise in both front-end and back-end development, we deliver robust and scalable websites that meet your exact specifications.</li>
</ul>


<h4><strong>2. SEO-Optimized Websites</strong></h4>
<ul>
  <li><strong>SEO-Friendly Code Structure</strong>
Our websites are built with search engine optimization in mind, helping your site rank better on Google and attract organic traffic.</li>
  <li><strong>Keyword Integration &amp; Analysis</strong>
We integrate the right keywords and optimize on-page elements to enhance your website’s visibility.</li>
  <li><strong>Local SEO</strong>
Our local SEO services can help your business become more visible to potential clients in your area, essential for reaching your target audience in Bhabua and beyond.</li>
</ul>


<h4><strong>3. E-commerce Solutions</strong></h4>
<ul>
  <li><strong>Fully-Functional Online Stores</strong>
From product listing to secure payment gateways, we develop complete e-commerce websites that streamline the buying process.</li>
  <li><strong>Easy Content Management Systems (CMS)</strong>
Our CMS solutions make it simple for you to manage your products, update your website, and keep it current without needing technical skills.</li>
</ul>


<h4><strong>4. User Experience (UX) and User Interface (UI) Design</strong></h4>
<ul>
  <li><strong>Intuitive, Engaging Interfaces</strong>
We focus on creating user-friendly interfaces that encourage engagement and improve user satisfaction.</li>
  <li><strong>UX Testing and Optimization</strong>
We conduct thorough UX testing to ensure that your website’s design promotes ease of use and conversion, helping turn visitors into loyal customers.</li>
</ul>


<h4><strong>5. Web Application Development</strong></h4>
<ul>
  <li><strong>Custom Web Apps</strong>
We create custom web applications to meet your unique business needs, from internal tools to client-facing applications.</li>
  <li><strong>Scalable and Secure</strong>
Our web applications are built to handle growth, ensuring they are secure, efficient, and capable of scaling with your business.</li>
</ul>


<h4><strong>6. Website Maintenance and Support</strong></h4>
<ul>
  <li><strong>Ongoing Support</strong>
After launch, we provide website maintenance and support to keep your site up-to-date, secure, and running smoothly.</li>
  <li><strong>Regular Updates and Performance Monitoring</strong>
We make sure your website stays fast, responsive, and optimized with regular updates and performance checks.</li>
</ul>

          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
