export default function features() {
  return (
    <>

   <section id="features" className="features">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2><i className="bi bi-braces"></i> Technology</h2>
          <p><i className="bi bi-code-slash"></i> I am Expert in</p>
        </header>

        <div className="row">

         {/*
<div className="col-lg-4 align-self-center">
            <img src="assets/img/features.png" className="img-fluid" alt="Freelancer Pankaj" />
          </div>
         */} 

          <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
            <div className="row align-self-center gy-4">

              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="200">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-html"></i>
                  <h3>HTML5 (11Yr+)</h3>
                </div>
              </div>

              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="300">
                <div className="feature-box d-flex align-items-center">
               <i className="bi bi-filetype-css"></i>
                  <h3>CSS3 (11Yr+)</h3>
                </div>
              </div>

              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="400">
                <div className="feature-box d-flex align-items-center">
                 <i className="bi bi-bootstrap"></i>
                  <h3>Bootstrap (10Yr+)</h3>
                </div>
              </div>

              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="500">
                <div className="feature-box d-flex align-items-center">
                 <i className="bi bi-filetype-java"></i>
                  <h3>Javascript (10Yr+)</h3>
                </div>
              </div>

              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="600">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-json"></i>
                  <h3>Jquery (10Yr+)</h3>
                </div>
              </div>
 <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
               <i className="bi bi-wordpress"></i>
                  <h3>WordPress (11Yr+)</h3>
                </div>
              </div>
              <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
               <i className="bi bi-filetype-jsx"></i>
                  <h3>React JS (8Yr+)</h3>
                </div>
              </div>
 <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-php"></i>
                  <h3>PHP (7Yr+)</h3>
                </div>
              </div>
               <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-sql"></i>
                  <h3>MySql (10Yr+)</h3>
                </div>
              </div>
               <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-cs"></i>
                  <h3>C# DOT NET (2Yr+)</h3>
                </div>
              </div>
               <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                <i className="bi bi-filetype-json"></i>
                  <h3>Angular JS (2Yr+)</h3>
                </div>
              </div>
               <div className="col-md-3" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
              <i className="bi bi-images"></i>
                  <h3>Photoshop (15Yr+)</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
     <section id="features2" className="features">
    <div className="container" data-aos="fade-up">
 <div className="row feture-tabs" data-aos="fade-up">
 <header className="section-header">
          <h2><i className="bi bi-yelp"></i> Factors</h2>
          <p>When Choosing a Website Designer</p>
        </header>
          <div className="col-lg-6">

            <ul className="nav nav-pills mb-4">
              <li>
                <a className="nav-link active" data-bs-toggle="pill" href="#tab1"><i className="bi bi-person-check"></i> Experience and Portfolio</a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2"><i className="bi bi-code-slash"></i> Specialization</a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                <p>Choosing the right website designer can make you quickly success of your online presence. Here are some factors to consider when selecting a website designer:</p>
                <div className="d-flex align-items-center mb-2">
                <i className="bi bi-person-check-fill"></i>
                  <h4>Experience and Portfolio</h4>
                </div>
                <p>Look for a designer with a solid track record of designing successful websites. Review their experience & website to see if their style and design approach match your preferences. Check for testimonials or reviews from past clients to get a better sense of their experience and expertise.</p>
                <div className="d-flex align-items-center mb-2">
                 <i className="bi bi-currency-rupee"></i>
                  <h4>Cost and Timeline</h4>
                </div>
                <p>Consider your budget and timeline when choosing a website designer. Ask for a detailed quote that includes all the services they will provide and their pricing structure. Make sure they can complete the project within your desired timeline.</p>
              </div>

              <div className="tab-pane fade show" id="tab2">
                <div className="d-flex align-items-center mb-2">
                <i className="bi bi-code-slash"></i>
                  <h4>Specialization</h4>
                </div>
                <p>Some website designers specialize in specific industries or niches. For example, if you're in the healthcare industry, look for a designer who has experience designing websites for healthcare professionals. They will have a better understanding of your business and can create a website that speaks to your target audience.</p>
                <div className="d-flex align-items-center mb-2">
                <i className="bi bi-cash"></i>
                  <h4>Communication and Collaboration</h4>
                </div>
                <p>Choose a website designer who is easy to communicate with and takes the time to understand your business goals and preferences. They should be willing to collaborate with you and provide regular updates throughout the design process.</p>
                 <div className="d-flex align-items-center mb-2">
                <i className="bi bi-earbuds"></i>
                  <h4>Customer Support and Maintenance</h4>
                </div>
                <p>After your website is launched, you'll need ongoing support and maintenance to keep it running smoothly. Choose a designer who offers reliable customer support and maintenance services.</p>
              </div>

           
            </div>

          </div>

          <div className="col-lg-6 align-self-center">
            <img src="assets/img/features-2.png" className="img-fluid imgfact" alt="Pankaj Website" />
          </div>
  </div>
</div>
</section>
<section id="features3" className="features">
<div className="container" data-aos="fade-up">
 <div className="row feature-icons" data-aos="fade-up">
   <header className="section-header">
          <h2><i className="bi bi-yelp"></i> Tools</h2>
          <p>My Essential Tools</p>
        </header>
    <div className="row">
         <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
          <img src="assets/img/features-3.png" className="img-fluid p-4" alt="Graphic Design Tools" />
        </div>
            <div className="col-xl-8 d-flex content">
              <div className="row align-self-center gy-4">
                <div className="col-md-6 icon-box" data-aos="fade-up">
                 <i className="bi bi-brush-fill"></i>
                  <div>
                    <h4>Graphic Design Tools</h4>
                    <p>Adobe Photoshop, Sketch, Figma, and Canva are some of the popular graphic design tools used by me. These tools help you create visually appealing designs and layouts for your website.</p>
                  </div>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                <i className="bi bi-menu-app-fill"></i>
                  <div>
                    <h4>Content Management Systems (CMS)</h4>
                    <p>CMS like WordPress, Drupal, and Joomla help you manage your website's content without any coding knowledge. You can easily add or edit content, images, and videos using these systems.</p>
                  </div>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
               <i className="bi bi-laptop"></i>
                  <div>
                    <h4>Front-End Development Tools</h4>
                    <p>HTML, CSS, and JavaScript are the building blocks of any website. Pankaj Web designer use front-end development tools like Bootstrap, Foundation, and Materialize to design responsive and interactive websites.</p>
                  </div>
                </div>

                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-file-earmark-code"></i>
                  <div>
                    <h4>Back-End Development Tools</h4>
                    <p>PHP, Python, and Ruby on Rails are some of the popular back-end development tools used by Pankaj. These tools help you create dynamic websites that can store and retrieve data from databases.</p>
                  </div>
                </div>

                <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="400">
                  <i className="ri-command-line"></i>
                  <div>
                    <h4>Website Testing and Optimization Tools</h4>
                    <p>Google Analytics, SEMrush, Ahref, Google Search Console, Hotjar, and Optimizely are some of the popular website testing and optimization tools used by web designers. These tools help you track user behavior and optimize your website's performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </section>
    </>
  );
}
