import React, { useEffect } from 'react';
import {Helmet} from 'react-helmet';
import AboutUs from "../Page/aboutus";
import Faqs from "../Page/faqs";
import Portfolios from "../Page/portfolios";
import Features from "../Page/features";
import Count from "../Page/count";
import Testimonials from "../Page/testimonials";
import Certificate from "../Page/Certificate";
import Contact from "../Page/contact";
{/*import StructuredData from "../Page/StructuredData";*/}

function App1() {
  return (
   <>
   <Helmet>

{/*<StructuredData />*/}
<title>Pankaj | Website Designing Company in Gurgaon</title>
<meta name="description" content="Top website designing company in Gurgaon, Delhi, Noida. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designing company in gurgaon, website developer in gurgaon, senior website developer, web development company in gurgaon, website maker in gurgaon, website design company, pankaj web designer, pankj, freelance website designer, website designer in gurgaon, website developer near me"/>
<meta property="og:title" content="Pankaj - Website Designing Company in Gurgaon" />
<meta property="og:description" content="Top Website Designing Company in Gurgaon, Delhi, Noida. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:url" content="https://www.pankj.com"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta property="og:site_name" content="Pankaj | Website Designing Company in Gurgaon" />
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designing Company in Gurgaon" />
<meta name="twitter:description" content="Top website designing company in Gurgaon, Delhi, Noida. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com" />
</Helmet>
<section id="hero" className="hero homepg d-flex align-items-center fixedabove">
<video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/pankaj-web-designer1.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
<div className="container-fluid">
<div className="row">
<div className="col-lg-6 d-flex flex-column">
<img fetchPriority="high" src="../assets/img/thyear.webp" className="homedes" alt="Website Designer" />
<div className="multiple_effects" id="text3d">Website <span></span></div>
<div className="content_sec">If you are looking for a <h1 className="headerdesign">Website Designing Company in Gurgaon</h1>, then you are in the right place. Hello, I am pankaj with <b>10+ years</b> of experience in website design & development. you can hire me as a freelancer for your dream projects.</div>
<div data-aos="fade-up" data-aos-delay="600">
<div className="text-center text-lg-start">
<a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
<span>About Me</span>
<i className="bi bi-arrow-right"></i>
</a>
</div>
</div>
{/*
 <div className="waviy">
   <span>Zyuyyuyu</span>
  </div>
 <div className="web_ment_loader">
  <span>W</span>
  <span>e</span>
  <span>b</span>
  <span>ㅤ</span>
  <span>D</span>
  <span>e</span>
  <span>s</span>
  <span>i</span>
  <span>g</span>
  <span>n</span>
  <span>e</span>
  <span>r</span>
</div>
<div id="BrushCursor">
<div className="p p1">Hello World</div>
<div className="p p2">Hello World</div>
<div className="p p3">
Hello World
<div className="cursor"></div>
</div>
</div>
<div className="box_inners">
<div className="inner">
<span>Hello World</span>
</div>
<div className="inner">
<span>Hello World</span>
</div>
</div>
<h2 className="multiple_effects">I am a Web-Developer and <span></span></h2>
<h2 className="multieffecctss">
<span>always be</span>
<div className="message">
    <div className="word1">close</div>
    <div className="word2">code</div>
    <div className="word3">creating</div>
  </div>
</h2>
  <div className="row">
  <div className="col-md-12">
      <span className="title-word title-word-1">This </span>
    <span className="title-word title-word-2">is </span>
    <span className="title-word title-word-3">Pankaj </span>
    <span className="title-word title-word-4">Singh </span>
  </div>
</div>
*/}
</div>
<div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">

<div className="wrapper">
<div className="img-area">
<div className="inner-area">
{/*<img src="../assets/img/pks2.png" alt="Pankaj Singh" />*/}
<div className="bgimg"></div>
</div>
</div>
{/*
<div className="name">Text Name</div>
<div className="buttons">
<button>Message</button>
<button>Subscribe</button>
</div>
<div className="social-share">
<div className="row">
        <i className="far fa-heart"></i>
        <i className="icon-2 fas fa-heart"></i>
        <span>20.4k</span>
      </div>
      <div className="row">
        <i className="far fa-comment"></i>
        <i className="icon-2 fas fa-comment"></i>
        <span>14.3k</span>
      </div>
      <div className="row">
        <i className="fas fa-share"></i>
        <span>12.8k</span>
      </div>
    </div>
*/}
</div>
</div>
</div>
</div> 

</div>
</section>
<main id="main">


 <AboutUs />
<Count />

{/*
<Features />
<Faqs />
<Portfolios /> */}
<Testimonials/>
<Certificate />
<Contact /> 
</main>
</>
  );
}
export default App1;
