import { Link } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import { history } from "../_helpers";
// import { useHistory } from 'react-router-dom';
import Nav from "../Page/nav";


import { useDispatch, useSelector } from "react-redux";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import React, { useState, useEffect } from "react";

const Header = () => {
    // const histor = useHistory(); 


   // const crossFunction = ()=>{
   //      histor.push("/");
   //      document.getElementById("overlay").style.display = "none";
   //  }

  return (
    <>
  <header id="header" className="header fixed-top">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

      <a href="/" className="logo d-flex align-items-center">
        <img fetchPriority="high" src="assets/img/logo.png" alt="Web Design Company Gurgaon" id="logo"/>
        <span></span>
      </a>

<Nav/>
    
    </div>
  </header>
    <div class="cursor cursor--large"></div>
<div class="cursor cursor--small"></div>

    </>
  );
}
export default Header;
