export default function aboutus() {
  return (
    <>
             
 <section id="contact" className="contact">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Contact</h2>
          <p>Connect with Me</p>
        </header>

        <div className="row gy-4">

          <div className="col-lg-6">

            <div className="row gy-4">
             
              <div className="col-md-6">
                <div className="info-box">
                <i className="bi bi-earbuds"></i>
                  <h3>Call Us</h3>
                  <p> <a href="tel:7838704822">+91-7838704822</a></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                 <i className="bi bi-envelope-open-heart"></i>
                  <h3>Email Us</h3>
                  <p><a href="mailto:csepksingh@gmail.com">csepksingh@gmail.com</a></p>
                </div>
              </div>
               <div className="col-md-6">
                <div className="info-box">
                <i className="bi bi-pin-map"></i>
                  <h3>Address</h3>
                  <p>DLF Cyber City, <br/>Gurgaon Haryana,122002 </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                 <i className="bi bi-alarm"></i>
                  <h3>Working Hours</h3>
                  <p>Monday - Friday<br/>11:00AM - 03:00AM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6">
            <form action="https://www.pankj.com/php/contact.php" method="post" className="php-email-form">
              <div className="row gy-4">

                <div className="col-md-12">
                <input type="text" className="hiddenclasss" name="title" />
                  <input type="text" name="name" minLength="2" maxLength="20" id="name" className="form-control" placeholder="Your Name" required />
                </div>
                  <div className="col-md-6 ">
                  <input type="text" minLength="10" maxLength="13" className="form-control" name="phone" id="phone" placeholder="Your Phone" required />
                </div>
                <div className="col-md-6">
                  <input type="email" minLength="10" maxLength="30"  className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
                <div className="col-md-12">
                  <textarea className="form-control" minLength="2" maxLength="150" name="message" rows="6" placeholder="Message" required></textarea>
                </div>
                <div className="col-md-12 text-center">
                  <input type="submit" className="form-control submitbtn" name="submit" placeholder="Submit" value="Claim a Website(Rs: 9,999 Only)" onclick="return SubmitDetails();" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  {/*  
  <section className="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14025.950155353696!2d77.08078733108762!3d28.494975919554356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d193840000001%3A0x73d45b324c28111e!2sPankaj%20%7C%20Website%20Designing%20Company%20In%20Gurgaon!5e0!3m2!1sen!2sin!4v1681029093335!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
  */}

    </>
  );
}
