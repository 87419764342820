import Tabs from "./Tabs";
import Items from "./Items";
import Data from "./Data";
import { useState } from "react";

function MyGallery(){

    const [data, setData] = useState(Data);
    const categoryData = Data.map((value)=>{
          return value.category
     });
    const tabsData= ["all", ...new Set(categoryData)];
    
    const filterCategory=(category) =>{
        if(category=="all"){
            setData(Data);
            return;
        }
       const filteredData =  Data.filter((value)=>{
           return value.category == category;
       })
       setData(filteredData);
    }
    return(
      <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2><i className="bi bi-brightness-alt-high"></i> Portfolio</h2>
          <p><i className="bi bi-person-check"></i> Check our latest work</p>
        </header>

        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                <ul id="portfolio-flters">
                 <Tabs filterCategory={filterCategory} tabsData={tabsData}/>
                   </ul>
            <Items data={data} />

        </div>

      </div>
    </section>
    
    )
}

export default MyGallery;