import React, { Component } from 'react'
import { Link } from "react-router-dom";


function openFuncation() {
    var element = document.getElementById("navmobile");
    element.classList.add("show-menu");
  }
  function closeFuncation() {
    var element = document.getElementById("navmobile");
    element.classList.remove("show-menu");
  }

export default class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            active: 'home'
        }
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
         this.setState({
            active: e.target.innerHTML
         });
    }

    render() {
        let homeStyle;
        let aboutStyle;
        let servicesStyle;
        let portfolioStyle;
        let certificateStyle;
        let contactStyle;

        if (this.state.active === 'Home') {
            homeStyle = { color: '#be3838' };
            aboutStyle = {};
            servicesStyle = {};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        } 
        else if (this.state.active === 'About Me') {
            homeStyle = {};
            aboutStyle = { color: '#be3838'};
            servicesStyle = {};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        } 
         else if (this.state.active === 'About') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = { color: '#be3838'};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        } 
          else if (this.state.active === 'Services') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = { color: '#be3838'};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        } 
          else if (this.state.active === 'Portfolio') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = {};
            portfolioStyle = { color: '#be3838'};
            certificateStyle = {};
            contactStyle = {};
        } 
         
         else if (this.state.active === 'Certificate') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = {};
            portfolioStyle = {};
            certificateStyle = { color: '#be3838'};
            contactStyle = {};
        } 
         else if (this.state.active === 'Get Started') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = {};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        } 
         else if (this.state.active === 'Contact') {
            homeStyle = {};
            aboutStyle = {};
            servicesStyle = {};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {color: '#be3838'};
        } 
        
        else {
            homeStyle = {color: '#be3838'};
            servicesStyle = {};
             aboutStyle = {};
            portfolioStyle = {};
            certificateStyle = {};
            contactStyle = {};
        }

        return (
         <>



 <nav id="navbar" className="navbar">
    <i className="bi bi-list mobile-nav-toggle" onClick={openFuncation}></i>
        <ul className="navmobile" id="navmobile" onClick={closeFuncation}>
<i className="bi bi-x-lg closebtn" onClick={closeFuncation}></i>
          <li><Link className="nav-link scrollto active" style={homeStyle} onClick={this._handleClick}  to="/">Home</Link></li>
          <li><Link className="nav-link scrollto"style={aboutStyle} onClick={this._handleClick}  to="about-me">About Me</Link></li>
         
           <li><Link className="nav-link scrollto" style={servicesStyle} onClick={this._handleClick} to="services">Services</Link></li>
          <li><Link className="nav-link scrollto" style={portfolioStyle} onClick={this._handleClick} to="portfolios">Portfolio</Link></li>
          <li><Link style={certificateStyle} onClick={this._handleClick} to="certificate">Certificate</Link></li>

          {/*
                   
<li className="dropdown"><Link href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></Link>
            <ul>
              <li><Link href="#">Drop Down 1</Link></li>
              <li className="dropdown"><Link href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></Link>
                <ul>
                  <li><Link href="#">Deep Drop Down 1</Link></li>
                  <li><Link href="#">Deep Drop Down 2</Link></li>
                  <li><Link href="#">Deep Drop Down 3</Link></li>
                  <li><Link href="#">Deep Drop Down 4</Link></li>
                  <li><Link href="#">Deep Drop Down 5</Link></li>
                </ul>
              </li>
              <li><Link href="#">Drop Down 2</Link></li>
              <li><Link href="#">Drop Down 3</Link></li>
              <li><Link href="#">Drop Down 4</Link></li>
            </ul>
          </li>
          */}
          <li><Link className="nav-link scrollto" style={contactStyle} onClick={this._handleClick} to="contact">Contact</Link></li>
          <li><a className="getstarted scrollto" href="tel:+917838704822" target="_blank"><i className="bi bi-earbuds"></i>&nbsp; Call Me</a></li>
        </ul>
      </nav>
        </>
        )
    }
}