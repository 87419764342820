import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesBackground = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };
  return (
    <div style={{ position: 'relative' }}>
      {/* Particles Component */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: {
            enable: true,
            zIndex: -1, // Ensure particles are in the background
          },
          particles: {
            number: {
              value: 150,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: ['#00ff99', '#00ccff', '#ff66cc', '#00ffcc'],
            },
            shape: {
              type: 'char',
              character: {
                value: ['{', '}', '(', '[', 'o', '<', '0', '1', '=', '+', '*', '#', 'Developer', 'Pankaj', 'HTML', 'React Js'],
                font: 'Courier New',
                weight: 'bold',
              },
            },
            opacity: {
              value: 0.6,
              random: true,
              anim: {
                enable: true,
                speed: 0.5,
                opacity_min: 0.2,
              },
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: true,
                speed: 0.5,
                size_min: 4,
              },
            },
            move: {
              enable: true,
              speed: 0.03,
              out_mode: 'out',
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onHover: {
                enable: true,
                mode: 'repulse',
              },
              onClick: {
                enable: true,
                mode: 'push',
              },
            },
            modes: {
              repulse: {
                distance: 100,
                duration: 0.4,
              },
              push: {
                quantity: 4,
              },
            },
          },
        }}
        style={{ pointerEvents: 'none' }} // Prevent particles from blocking clicks
      />
    </div>
  );
};

export default ParticlesBackground;
