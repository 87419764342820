import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Raipur</title>
<meta name="description" content="Top website designer in Raipur. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website developer in raipur, web design in raipur, web development company in raipur, website development company in raipur, website development in raipur, website designer in raipur, web design company in raipur, website design company in raipur, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Raipur" />
<meta property="og:description" content="Top Website Designer In Raipur. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Raipur" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-raipur"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Raipur" />
<meta name="twitter:description" content="Top website designer in Raipur. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-raipur" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Raipur</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Raipur</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Are you in search of a talented website designer in Raipur to transform your online presence? I’m Pankaj, a dedicated website designer focused on delivering exceptional, responsive, and SEO-friendly websites tailored specifically to your business needs.
<h3>Why Choose Pankaj for Website Design in Raipur?</h3>
<ol>
  <li><strong>Custom Designs That Reflect Your Brand</strong>
Every business has its unique story. Whether you require a corporate website, an e-commerce platform, a personal blog, or a portfolio, I work closely with you to create a design that embodies your brand and resonates with your audience.</li>
  <li><strong>SEO-Optimized for Increased Visibility</strong>
A stunning website is only effective if it can be found. I integrate essential SEO strategies into all my designs to enhance your website's visibility on search engines, helping attract more visitors and potential customers.</li>
  <li><strong>Comprehensive Design and Development Services</strong>
I provide a full range of website design services, including redesigns and e-commerce solutions. Utilizing popular CMS platforms like WordPress, I ensure that you can easily manage and update your website after it goes live.</li>
  <li><strong>Transparent Communication and Ongoing Support</strong>
Clear communication is key to successful projects. I provide transparent timelines and pricing, and my support doesn’t end at launch. I offer ongoing maintenance and assistance to keep your website secure and functional.</li>
</ol>
<h3>Services Offered</h3>
<ul>
  <li><strong>Custom Website Design &amp; Redesign</strong>
Tailored, user-focused designs that showcase your brand’s strengths and deliver an engaging user experience.</li>
  <li><strong>E-commerce Website Development</strong>
Secure, user-friendly e-commerce platforms designed to drive sales and enhance the shopping experience for your customers.</li>
  <li><strong>Corporate and Personal Websites</strong>
Professional websites crafted to elevate your online presence and engage your audience effectively in Raipur and beyond.</li>
  <li><strong>SEO and Basic Digital Marketing</strong>
All websites are built with foundational SEO practices. For advanced digital marketing strategies, I collaborate with trusted experts to meet your needs.</li>
</ul>
<h3>Let’s Build Your Online Presence Together!</h3>
Contact me today to discuss your project. Together, we can create a website that not only represents your brand in Raipur but also sets you apart in the digital landscape.
<h2>Frequently Asked Questions</h2>

<h4>What types of websites does Pankaj design in Raipur?</h4>
<p>Pankaj offers a wide range of website design services, including corporate websites, e-commerce platforms, personal blogs, and portfolios. Each website is customized to reflect the client's unique brand identity and goals.</p>

<h4>Why should I choose Pankaj as my website designer?</h4>
<p>Pankaj is a skilled designer with a strong focus on creating visually appealing and functional websites. His designs are responsive, SEO-friendly, and tailored to enhance user experience, ensuring that your brand stands out online.</p>

<h4>Does Pankaj provide SEO services?</h4>
<p>Yes, Pankaj incorporates essential SEO strategies into every website design to improve its visibility on search engines. For more comprehensive digital marketing needs, he collaborates with trusted partners.</p>

<h4>How much does a website design project cost?</h4>
<p>The cost of website design varies depending on the project's complexity, features, and specific requirements. Pankaj provides a detailed quote after discussing your needs to ensure clarity and transparency.</p>

<h4>Can I update my website content after it’s launched?</h4>
<p>Absolutely! Pankaj uses user-friendly CMS platforms like WordPress, allowing clients to manage and update their content easily post-launch. He also offers guidance to help you navigate updates confidently.</p>

<h4>Does Pankaj offer ongoing support after my website goes live?</h4>
<p>Yes, Pankaj provides ongoing maintenance and support services to ensure your website remains secure and functional. This includes troubleshooting, updates, and performance enhancements as needed.</p>

<h4>How long does it take to complete a website design project?</h4>
<p>The timeline for a website design project can vary based on its complexity. After discussing your project details, Pankaj provides an estimated timeframe, which typically ranges from a few weeks to a couple of months.</p>

<h4>How can I contact Pankaj for website design services in Raipur?</h4>
<p>You can reach out to Pankaj through the contact form on this website or by using the provided contact information. He’s ready to discuss your project and help you build a website that meets your business objectives.</p>

          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
