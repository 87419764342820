import Slider from "react-slick";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import AboutUs from "../aboutus";
import Faqs from "../faqs";
import Portfolios from "../portfolios";
import Features from "../features";
import Pricing from "../pricing";
import Services from "../services";
import Teams from "../teams";
import Testimonials from "../testimonials";
import Clients from "../clients";
import Certificate from "../Certificate";
import Contact from "../contact";


function App1() {
  return (
   <>
   <Helmet>
   <title>Pankaj - Web Developer In Gurgaon</title>
  <meta name="description" content="Website designing company in gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India."/>
  <meta name="keywords" content="pankaj, pankj, web designers near me, website designer near me, web designing company in gurgaon, website designing company in gurgaon, website development company in gurgaon,freelance web designer, web design gurgaon, website design gurgaon, web design companies near me, web design company, website design company"/>
<meta property="og:title" content="Pankaj - Web Developer In Gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Web Developer In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<meta property="og:site_name" content="Pankaj - Web Developer In Gurgaon | website development company in gurgaon" />
<meta property="og:url" content="https://www.pankj.com/pricing"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj - Web Developer In Gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Web Developer In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<link rel="canonical" href="https://www.pankj.com/pricing" />
        </Helmet>
    <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
        <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
 <h1 className="multiple_effects" id="text3d">Pricing</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="Website Designers In Gurgaon" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   



    <main id="main">

 <section id="pricing" className="pricing">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Pricing</h2>
          <p>Check our Pricing</p>
        </header>

        <div className="row gy-4" data-aos="fade-left">

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div className="box">
              <h3 style={{color: '#07d5c0'}}>Personal</h3>
              <div className="price"><sup>₹</sup>2,500<span> / Website</span></div>
              <img src="assets/img/pricing-free.png" className="img-fluid" alt="Website Development Services" />
              <ul>
                <li>1 Pages</li>
                <li className="na">Slider</li>
                <li>About Us</li>
                <li>Social Profile Link</li>
                <li>Contact Us</li>
                <li>1 Revisions</li>
                  <li className="na">Payment Gateway</li>
                <li>1 Days Delivery*</li>
                <li>200 Rs/Hr Support</li>
                <li className="na pricecut">₹12,500</li>
              </ul>
              <a href="#" className="btn-buy">Claim Now</a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div className="box">
              <span className="featured">Featured</span>
              <h3 style={{color: '#65c600'}}>Startup</h3>
              <div className="price"><sup>₹</sup>11,000<span> / Website</span></div>
              <img src="assets/img/pricing-starter.png" className="img-fluid" alt="Best Hospitality Website Design" />
              <ul>
                <li>10 Pages</li>
                <li>Slider</li>
                <li>About Us</li>
                <li>Social Profile Link</li>
                <li>Contact Us</li>
                <li>3 Revisions</li>
                <li className="na">Payment Gateway</li>
                <li>5 Days Delivery*</li>
                <li>300 Rs/Hr Support</li>
                <li className="na pricecut">₹35,000</li>
              </ul>
              <a href="#" className="btn-buy">Claim Now</a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div className="box">
              <h3 style={{color: '#ff901c'}}>Ecommerce</h3>
              <div className="price"><sup>₹</sup>25,000<span> / Website</span></div>
              <img src="assets/img/pricing-business.png" className="img-fluid" alt="Best Web Designer In Gurgaon" />
              <ul>
                <li>50 Pages</li>
                <li>Slider</li>
                <li>About Us</li>
                <li>Social Profile Link</li>
                <li>Contact Us</li>
                <li>Unlimited Revisions</li>
                  <li>Payment Gateway</li>
                <li>10 Days Delivery*</li>
                <li>500 Rs/Hr Support</li>
                <li className="na pricecut">₹65,000</li>
              </ul>
              <a href="#" className="btn-buy">Claim Now</a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="box">
              <h3 style={{color:'#ff0071'}}>Corporate</h3>
              <div className="price"><sup>₹</sup>75,000<span> / Website</span></div>
              <img src="assets/img/pricing-ultimate.png" className="img-fluid" alt="Website Designer" />
              <ul>
                <li>Unlimited Pages</li>
                <li>Slider</li>
                <li>About Us</li>
                <li>Social Profile Link</li>
                <li>Contact Us</li>
                <li>Unlimited Revisions</li>
                  <li>Payment Gateway</li>
                <li>45 Days Delivery*</li>
                <li>1500 Rs/Hr Support</li>
                <li className="na pricecut">₹125,500</li>
              </ul>
              <a href="#" className="btn-buy">Claim Now</a>
            </div>
          </div>

        </div>

      </div>

    </section>
    </main>
    </>
  );
}
export default App1;
