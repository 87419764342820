import Slider from "react-slick";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import AboutUs from "../aboutus";
import Faqs from "../faqs";
import Portfolios from "../portfolios";
import Features from "../features";
import Pricing from "../pricing";
import Services from "../services";
import Teams from "../teams";
import Testimonials from "../testimonials";
import Clients from "../clients";
import Certificate from "../Certificate";
import Contact from "../contact";


function App1() {
  return (
   <>
   <Helmet>
    <title>Pankaj - Website Designing Services In Gurgaon</title>
  <meta name="description" content="Website Designing Services In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India."/>
  <meta name="keywords" content="pankaj, pankj, web designers near me, website designer near me, web designing company in gurgaon, Website Designing Services In Gurgaon, website development company in gurgaon,freelance web designer, web design gurgaon, Website Designers In Gurgaon, web design companies near me, web design company, website designer in gurgaon"/>
<meta property="og:title" content="Pankaj - Website Designing Services In Gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Website Designing Services In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<meta property="og:site_name" content="Pankaj - Website Designing Services In Gurgaon | website development company in gurgaon" />
<meta property="og:url" content="https://www.pankj.com/gservices"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj - Website Designing Services In Gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Website Designing Services In Gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Me, one of Best Web Design and Development Companies in India." />
<link rel="canonical" href="https://www.pankj.com/services" />
        </Helmet>
  <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/pankaj-web-designer1.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
        <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
 <h1 className="multiple_effects" id="text3d">Services</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/123.png" alt="Website Design Gurgaon" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
 <Services />
    <Contact /> 
    </main>
    </>
  );
}
export default App1;
