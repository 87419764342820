import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Sangli-Miraj & Kupwad</title>
<meta name="description" content="Top website designer in Sangli-Miraj & Kupwad. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designing company in Sangli-Miraj & Kupwad, website developer in Sangli-Miraj & Kupwad, senior website developer, web development company in Sangli-Miraj & Kupwad, website maker in Sangli-Miraj & Kupwad, website design company, pankaj web designer, pankj, freelance website designer, website designer in Sangli-Miraj & Kupwad, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Sangli-Miraj & Kupwad" />
<meta property="og:description" content="Top Website Designer In Sangli-Miraj & Kupwad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Sangli-Miraj & Kupwad" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-sangli-miraj-and-kupwad"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Sangli-Miraj & Kupwad" />
<meta name="twitter:description" content="Top website designer in Sangli-Miraj & Kupwad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-sangli-miraj-and-kupwad" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer In Sangli-Miraj & Kupwad</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Sangli-Miraj & Kupwad</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Are you looking for a professional website designer in Sangli-Miraj & Kupwad who can bring your online vision to life? I’m Pankaj, a dedicated website designer specializing in creating visually appealing and highly functional websites that help you connect with your audience and achieve your business goals.
<h3>Why Choose Pankaj for Your Website Design Needs?</h3>
<ol>
  <li><strong>Customized Website Solutions</strong>
With years of experience, I understand that every business is unique. I tailor each website to match your brand’s identity and objectives, whether you need a corporate site, an e-commerce platform, a personal blog, or a portfolio.</li>
  <li><strong>SEO-Optimized for Better Search Rankings</strong>
A well-designed website should also be easily discoverable. I incorporate essential SEO practices into all my designs, ensuring that your website ranks higher on search engines like Google and attracts more potential customers.</li>
  <li><strong>Comprehensive Design Services</strong>
From initial concept to completion, I offer a full range of services, including website development, redesigns, and e-commerce solutions. I use popular CMS platforms like WordPress, enabling you to manage your content independently after launch.</li>
  <li><strong>Transparent Pricing and Ongoing Support</strong>
I believe in clear communication and transparent pricing. After understanding your project’s requirements, I provide a detailed estimate with no hidden costs. Additionally, I offer ongoing support and maintenance to keep your website running smoothly.</li>
</ol>
<h3>Services Offered</h3>
<ul>
  <li><strong>Custom Website Design &amp; Redesign</strong>
Unique, modern designs that provide an engaging user experience while clearly communicating your brand’s identity.</li>
  <li><strong>E-commerce Website Development</strong>
Secure, user-friendly e-commerce platforms that enhance customer engagement and maximize conversions.</li>
  <li><strong>Corporate and Personal Websites</strong>
Professionally designed websites that showcase your business or personal projects, ensuring a smooth and memorable experience for visitors.</li>
  <li><strong>Basic SEO and Digital Marketing</strong>
All websites are designed with SEO best practices in mind. For more advanced digital marketing needs, I can collaborate with trusted partners.</li>
</ul>
<h3>Ready to Take Your Online Presence to the Next Level?</h3>
Get in touch today to discuss your project. Let’s work together to build a website that truly represents your brand in Sangli-Miraj & Kupwad and beyond.
<h2>Frequently Asked Questions</h2>

<h4>What types of websites can Pankaj design in Sangli-Miraj & Kupwad?</h4>
<p>Pankaj specializes in creating various types of websites, including corporate sites, e-commerce platforms, personal blogs, and portfolios. Each website is tailored to meet the specific needs and objectives of the client.</p>

<h4>Why should I choose Pankaj as my website designer?</h4>
<p>Pankaj offers high-quality, responsive, and SEO-friendly websites designed with attention to detail. His years of experience ensure that each project effectively represents your brand and delivers a seamless user experience.</p>

<h4>Does Pankaj provide SEO services for websites?</h4>
<p>Yes, Pankaj incorporates essential SEO practices in all his website designs to improve search engine visibility. For advanced SEO strategies, he collaborates with trusted digital marketing experts as needed.</p>

<h4>How much does it cost to design a website with Pankaj?</h4>
<p>The cost of designing a website varies based on factors such as project complexity, the number of pages, and additional features. Pankaj provides customized quotes after understanding your requirements and budget.</p>

<h4>Can I update my website content after it’s launched?</h4>
<p>Absolutely! Pankaj builds websites using user-friendly CMS platforms like WordPress, allowing you to manage and update your content independently after the site is live. He also provides training and guidance to assist you with updates.</p>

<h4>What if I need help with my website after it’s live?</h4>
<p>Pankaj offers ongoing maintenance and support for clients, including troubleshooting, updates, and performance improvements, to ensure that your website remains secure and functional.</p>

<h4>How long does it take to complete a website design project?</h4>
<p>The timeline for completing a website design project depends on its complexity and specific requirements. Pankaj provides a realistic timeline after discussing your project, typically ranging from a few weeks to a couple of months.</p>

<h4>How can I contact Pankaj for my website design project?</h4>
<p>You can reach Pankaj through the contact form on this website or by using the provided contact information. He is ready to discuss your project and help you create a website that meets your business goals in Sangli-Miraj & Kupwad.</p>


          </div>

        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
