import React, { useEffect, useState } from "react";

const DeveloperCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  const values = [
    '{', '(', '[', 'Wordpress', '<', '0', '1', '=', '+', '*', '#',
    'Developer', 'Pankaj', 'HTML', 'React Js'
  ];

  const colors = [
    'rgba(255, 99, 132, 0.8)', // Red
    'rgba(54, 162, 235, 0.8)', // Blue
    'rgba(255, 159, 64, 0.8)', // Orange
    'rgba(75, 192, 192, 0.8)', // Green
    'rgba(153, 102, 255, 0.8)', // Purple
    'rgba(255, 206, 86, 0.8)'  // Yellow
  ];

  // Update cursor position
  const updatePosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  // Show/Hide cursor on mouse events
  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  // Hover effect on specific elements
  const handleHover = (e) => {
    if (
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "A" ||
      e.target.className.includes("hover-target")
    ) {
      setIsHovering(true);
    } else {
      setIsHovering(false);
    }
  };

  // Create particle blast effect on click
  const handleClick = (e) => {
    const particleCount = 20; // Number of particles
    for (let i = 0; i < particleCount; i++) {
      createParticle(e.clientX, e.clientY);
    }
  };

  // Function to create individual particles
  const createParticle = (x, y) => {
    const particle = document.createElement("div");
    particle.className = "particle-blast";

    // Increase the particle size range for bigger particles
    const size = Math.random() * 10 + 20; // Particle size between 20px and 30px (increased size)
    const angle = Math.random() * 2 * Math.PI; // Random angle (0 to 2π)
    const distance = Math.random() * 100 + 50; // Random distance (50px to 150px)
    const duration = Math.random() * 0.8 + 0.4; // Random duration (0.4s to 1.2s)

    // Randomly pick a value for this particle
    const randomValue = values[Math.floor(Math.random() * values.length)];
    particle.innerText = randomValue;

    // Randomly pick a color for this particle
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    particle.style.backgroundColor = randomColor;

    // Set particle styles
    particle.style.width = `${size}px`;
    particle.style.height = `${size}px`;
    particle.style.left = `${x}px`;
    particle.style.top = `${y}px`;
    particle.style.fontSize = `${size / 2}px`; // Font size proportional to particle size
    particle.style.textAlign = 'center';
    particle.style.lineHeight = `${size}px`; // Vertically center the text
    particle.style.transition = `transform ${duration}s ease-out, opacity ${duration}s ease-out`;

    // Append particle to the document body
    document.body.appendChild(particle);

    // Trigger particle animation
    requestAnimationFrame(() => {
      const transformX = Math.cos(angle) * distance;
      const transformY = Math.sin(angle) * distance;
      particle.style.transform = `translate(${transformX}px, ${transformY}px)`;
      particle.style.opacity = "0";
    });

    // Remove the particle after animation ends
    particle.addEventListener("transitionend", () => {
      particle.remove();
    });
  };

  useEffect(() => {
    document.addEventListener("mousemove", updatePosition);
    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("mouseover", handleHover);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mouseover", handleHover);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div
      className={`developer-cursor ${isVisible ? "" : "hidden"} ${
        isHovering ? "hovered" : ""
      }`}
      style={{ top: `${position.y}px`, left: `${position.x}px` }}
    >
      <span className="cursor-symbol">{isHovering ? ">" : "_"}</span>
    </div>
  );
};

export default DeveloperCursor;
