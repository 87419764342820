import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Amritsar</title>
<meta name="description" content="Top website designer in Amritsar. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designer in amritsar, web design company amritsar, web development company in amritsar, website development company in amritsar, web development in amritsar, website developer amritsar, website developer in amritsar, website design company in amritsar, web design amritsar, website development in amritsar, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Amritsar" />
<meta property="og:description" content="Top Website Designer In Amritsar. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Amritsar" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-amritsar"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Amritsar" />
<meta name="twitter:description" content="Top website designer in Amritsar. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-amritsar" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Amritsar</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Amritsar</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Welcome to Pankaj's Web Design Services! Based in the historic city of Amritsar, I specialize in creating professional and engaging websites that help businesses and individuals stand out online. With a focus on user experience and modern design, I am dedicated to crafting websites that not only look great but also function seamlessly to meet your goals.
<h3>My Services:</h3>
<h4><strong>Custom Website Design</strong></h4>
Every brand is unique, and your website should reflect that. I create custom website designs tailored to your specific needs and preferences. Whether you require a corporate website, a creative portfolio, or an e-commerce platform, I’ll ensure your site captures your vision and engages your audience.
<h4><strong>E-Commerce Solutions</strong></h4>
Ready to start selling online? I provide comprehensive e-commerce solutions that enable you to launch and manage your online store effectively. My e-commerce websites come equipped with secure payment gateways, intuitive navigation, and appealing product displays to enhance the shopping experience for your customers.
<h4><strong>Responsive Web Development</strong></h4>
With the increasing use of mobile devices, having a responsive website is essential. I ensure that all websites I design are fully responsive, providing an optimal viewing experience across all devices, including desktops, tablets, and smartphones.
<h4><strong>SEO-Friendly Websites</strong></h4>
To be successful online, your website needs to be visible on search engines. I implement SEO best practices during the design process to improve your site's ranking. This includes optimizing your content, images, and site structure to attract organic traffic and enhance your online presence.
<h4><strong>Website Redesign</strong></h4>
Is your current website not meeting your expectations? I offer website redesign services to give your site a fresh look and improved functionality. My goal is to enhance user experience, optimize performance, and align the design with your current business objectives.
<h4><strong>Content Management Systems (CMS)</strong></h4>
I utilize popular content management systems like WordPress, which allow you to manage your website content effortlessly. With a user-friendly interface, you can easily update your site without any technical skills, giving you complete control over your online presence.
<h4><strong>Maintenance &amp; Support</strong></h4>
Once your website goes live, I provide ongoing maintenance and support services to ensure everything runs smoothly. From routine updates to troubleshooting issues, I’m here to help whenever you need assistance.
<h4><strong>Digital Marketing &amp; SEO Services</strong></h4>
In addition to web design, I offer basic digital marketing services to boost your online presence. Whether it’s social media marketing, email campaigns, or content strategies, I can help you effectively reach and engage your target audience.
<h3>Why Choose Me?</h3>
<ul>
  <li><strong>Experience</strong>: With over a decade of experience in web design and development, I deliver high-quality results tailored to your needs.</li>
  <li><strong>Client-Centric Approach</strong>: I prioritize understanding your vision and goals, ensuring that the final product exceeds your expectations.</li>
  <li><strong>Affordable Pricing</strong>: I believe in providing quality services at competitive rates that fit your budget.</li>
  <li><strong>Timely Delivery</strong>: I am committed to respecting deadlines and delivering projects on time without compromising quality.</li>
</ul>
Let’s work together to create a stunning website that elevates your brand and connects with your audience. Contact me today for a consultation and a free quote!
<h2>Frequently Asked Questions</h2>

<h4>1. What services do you offer as a website designer?</h4>
<p>I provide a comprehensive range of services, including custom website design, e-commerce solutions, responsive web development, SEO-friendly websites, website redesign, CMS implementation, ongoing maintenance and support, and basic digital marketing services.</p>

<h4>2. How long does it take to design and develop a website?</h4>
<p>The timeline for designing and developing a website can vary based on its complexity and your specific requirements. Generally, a basic website takes about 2-4 weeks, while more complex projects may take longer. I will provide you with a detailed timeline during our initial consultation.</p>

<h4>3. Will my website be mobile-friendly?</h4>
<p>Yes! All websites I create are fully responsive, ensuring they look great and function well on all devices, including desktops, tablets, and smartphones. This guarantees an excellent user experience for all visitors.</p>

<h4>4. Can you help with SEO for my website?</h4>
<p>Absolutely! I incorporate SEO best practices during the website design process to improve your site's visibility on search engines. This includes optimizing your site's structure, content, and images to attract organic traffic.</p>

<h4>5. Do you provide ongoing support after the website is launched?</h4>
<p>Yes, I offer ongoing maintenance and support services to ensure your website runs smoothly after launch. This includes regular updates, troubleshooting, and assistance with any issues that may arise.</p>

<h4>6. How do I update my website after it’s launched?</h4>
<p>If you choose to use a Content Management System (CMS) like WordPress, you will have the ability to update your website content independently. I will provide you with training on how to manage your site, and I'm always available to assist you with any other changes or updates you may need.</p>

<h4>7. What is the cost of your website design services?</h4>
<p>The cost of website design services varies based on factors such as the complexity of the project, features required, and your specific needs. I provide a free quote after discussing your project in detail to ensure a solution that fits your budget.</p>

<h4>8. Can you redesign my existing website?</h4>
<p>Yes, I offer website redesign services to refresh and improve your current site. This may involve updating the layout, enhancing functionality, and optimizing for better performance and user experience.</p>

<h4>9. How do we get started on a project?</h4>
<p>To get started, simply contact me through my website or reach out via email or phone. We’ll schedule a consultation to discuss your project goals, timeline, and budget, allowing us to create a tailored plan for your website.</p>

<h4>10. Why should I choose you as my website designer?</h4>
<p>With over a decade of experience, a client-centric approach, and a commitment to delivering high-quality results on time, I am dedicated to helping you create a website that meets your needs and exceeds your expectations. Your satisfaction is my top priority!</p>


          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
