export default function faqs() {
  return (
    <>

    <section id="faq" className="faq">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2><i className="bi bi-patch-question-fill"></i> F.A.Q</h2>
          <p><i className="bi bi-question-diamond"></i> Frequently Asked Questions</p>
        </header>

        <div className="row">
          <div className="col-lg-6">
            <div className="accordion accordion-flush" id="faqlist1">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                    Can I design my own website without hiring a web designer?
                  </button>
                </h2>
                <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
                  Yes, it's possible to design your own website using website builders or templates, but it may not always result in a professional and effective website. Hiring a web designer can ensure that your website is visually appealing, functional, and optimized for search engines.


                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                   What should I look for in a web designer's portfolio?
                  </button>
                </h2>
                <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
                   When reviewing a web designer's portfolio, look for their previous work to assess their design style, creativity, and ability to create visually appealing and functional websites. Also, consider the industries they have worked with and the types of websites they have designed.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                   How important is responsive design for my website?
                  </button>
                </h2>
                <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
                   Responsive design is crucial for modern websites as it ensures that your website looks great and functions well on all devices, including desktops, tablets, and smartphones. With the increasing use of mobile devices for browsing, responsive design is essential to provide a seamless user experience.
                  </div>
                </div>
              </div>
 <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                   Can I update my website after it's been designed and launched?

                  </button>
                </h2>
                <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
                  Yes, most websites are built with a content management system (CMS) that allows you to update and manage your website's content even after it has been designed and launched. You can add new pages, update existing content, upload images, and make other changes as needed. Your web designer can guide you on how to effectively manage and update your website using the CMS.

                  </div>
                </div>
              </div>
           
           <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                  What should I do if I encounter issues with my website after it's been designed and launched?

                  </button>
                </h2>
                <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
If you encounter issues with your website after it has been designed and launched, it's best to contact your web designer for support. They can troubleshoot and fix any technical issues, ensure that your website is running smoothly, and provide ongoing maintenance and updates as needed.

                  </div>
                </div>
              </div>
                  <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
Can a web designer also handle website hosting and domain registration?
                  </button>
                </h2>
                <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
Some web designers may offer website hosting and domain registration services as part of their packages, while others may not. It's important to clarify with your web designer if they provide these services or if you need to arrange them separately. Proper website hosting and domain registration are essential for ensuring that your website is accessible online.

                  </div>
                </div>
              </div>
 <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
How often should I update my website's design?
                  </button>
                </h2>
                <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
The frequency of updating your website's design may vary depending on your specific needs and goals. However, it's generally recommended to update your website's design every 2-3 years to keep it visually appealing, modern, and aligned with the latest web design trends. Regular updates can also help improve user experience and keep your website relevant in a rapidly evolving digital landscape.


                  </div>
                </div>
              </div>
 <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8">
What are some tips for creating an engaging website?
                  </button>
                </h2>
                <div id="faq-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                  <div className="accordion-body">
Some tips for creating an engaging website include using high-quality images and videos, incorporating interactive elements like animations and transitions, keeping your website organized and easy to navigate, and using clear and concise language to convey your message. Additionally, regularly updating your website with fresh content and staying up-to-date with the latest design trends can help keep your website engaging and relevant.


                  </div>
                </div>
              </div>
 </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion accordion-flush" id="faqlist2">

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                   What are the key qualities of a good web designer?
                  </button>
                </h2>
                <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
                   A good web designer possesses several key qualities, including creativity, attention to detail, strong visual design skills, proficiency in web design tools and technologies, good communication skills, ability to understand and interpret client requirements, and ability to work well under pressure and meet deadlines.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                   How much does web design services cost?
                  </button>
                </h2>
                <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
The cost of web design services can vary greatly depending on various factors such as the complexity of the project, the features and functionality required, the level of customization, and the experience and location of the web designer. It's best to request quotes from multiple web designers and compare their pricing and services to determine the best fit for your budget.

                  </div>
                </div>
              </div>
<div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                    What services do web designers offer?
                  </button>
                </h2>
                <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
Web designers offer a wide range of services, including website design and development, user experience (UX) design, user interface (UI) design, graphic design, responsive design, branding and identity design, website maintenance, and search engine optimization (SEO). Some web designers may also offer additional services such as e-commerce development, content creation, and website hosting.


                  </div>
                </div>
              </div>
<div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-4">
                   How can I ensure my website is SEO-optimized?
                  </button>
                </h2>
                <div id="faq2-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
To ensure your website is SEO-optimized, work closely with your web designer to implement SEO best practices, such as creating a well-structured website with proper heading tags, optimizing images and other media, using descriptive URLs, creating unique and high-quality content, and implementing responsive design. Additionally, ensure that your website is mobile-friendly, loads quickly, and has proper meta tags, title tags, and meta descriptions.


                  </div>
                </div>
              </div>

<div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-5">
What is the difference between front-end and back-end development?
                  </button>
                </h2>
                <div id="faq2-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
Front-end development refers to the part of web development that deals with the visual and interactive aspects of a website, while back-end development deals with the behind-the-scenes work, such as server configuration and database management.



                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-6">
What is a CMS, and why is it important for web design?
                  </button>
                </h2>
                <div id="faq2-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
A CMS or content management system is a software application that allows you to manage the content of your website without the need for coding knowledge. It makes it easier to create, edit, and publish content on your website, which is crucial for keeping your website up-to-date and engaging.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-7">
What are some benefits of using website testing and optimization tools?
                  </button>
                </h2>
                <div id="faq2-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
Website testing and optimization tools can help you improve your websites performance and user experience. By tracking user behavior and testing different elements of your website, you can identify areas for improvement and make data-driven decisions to optimize your website.



                  </div>
                </div>
              </div>



              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-8">
Why is responsive design important for websites?
                  </button>
                </h2>
                <div id="faq2-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                  <div className="accordion-body">
Responsive design ensures that your website looks good and functions well on all devices, including desktops, tablets, and smartphones. With the growing use of mobile devices, having a responsive website is essential for providing a positive user experience and improving your website's visibility on search engines.


                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

    </section>
    </>
  );
}
