
export default function aboutus() {
  return (
    <>
   <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row gx-0">
          <div className="col-lg-8 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3><i className="bi bi-code-slash"></i> Who I Am</h3>
              <h2><i className="bi bi-person-check"></i> Web Designer And Front-end Developer</h2>
              <p>I'm full-stack website designing company in gurgaon Since 2013, I have completed 500+ website design project for our clients in past 10 years.</p>
<p>My website design & development services are wonderful in quality speed, responsive, cross-browser tested, and SEO-friendly solutions that rank effortlessly on Google/Bing/Baidu/Yandex/yahoo in quick times. I am consistently receiving positive feedback from my past clients.
</p>
<p>
If you are looking best website developer in gurgaon, you can contact me.
</p>
<p className="fh5co-sub to-animate">Website development is not just a job for me, it's my passion.</p>
              <div className="text-center text-lg-start">
                <a href="/about-me" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src={"../assets/img/ericssoncertificate.avif"} className="img-fluid" alt="website designing company in gurgaon" />
          </div>

        </div>
      </div>

    </section>
        <section id="values" className="values">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2><i className="bi bi-code-square"></i> Core Services</h2>
          <p><i className="bi bi-code-slash"></i> Value-Added Service(VAS)</p>
        </header>

        <div className="row">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="box">
              <img src="../assets/img/001.avif" className="img-fluid" alt="Web Apps Design" />
              <h3>Website Design</h3>
              <p>Whether its a One Page website or a full featured dynamic Website our focus is on quality, creative and easy to rank on search engine which is never compromised. I regularly use modern techniques which makes quickly growth.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <img src="../assets/img/002.avif" className="img-fluid" alt="Responsive Design" />
              <h3>Responsive Design</h3>
              <p>Designing user interfaces (UI) for mobile and tablet devices like iPhone, iPad and Android is one of the most challenging task for every website designer. Whether its Mobile Tablets or desktop i design website fully Responsive.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div className="box">
              <img src="../assets/img/003.avif" className="img-fluid" alt="Website Development" />
              <h3>Website Development</h3>
              <p>So now you got a good design, making it fully functional including enquiries, newsletter & database. I have 10yr+ Experience in Front end development on responsive framework like Bootstrap5 & React JS</p>
            </div>
          </div>

        </div>

      </div>

    </section>
    </>
  );
}
