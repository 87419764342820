import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Ghaziabad</title>
<meta name="description" content="Top website designer in Ghaziabad. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website design company ghaziabad, web development company ghaziabad, web development company in ghaziabad, website development company in ghaziabad, website designer in ghaziabad, website developer in ghaziabad, web designer in ghaziabad, website development in ghaziabad, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Ghaziabad" />
<meta property="og:description" content="Top Website Designer In Ghaziabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Ghaziabad" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-ghaziabad"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Ghaziabad" />
<meta name="twitter:description" content="Top website designer in Ghaziabad. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-ghaziabad" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Ghaziabad</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Ghaziabad</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Welcome to Pankaj's Web Design Services! Based in Ghaziabad, I specialize in creating innovative and effective websites that cater to the diverse needs of businesses and individuals. With a focus on modern design, user experience, and functionality, I aim to provide you with a powerful online presence that drives engagement and growth.
<h3>My Services:</h3>
<h4><strong>Custom Website Design</strong></h4>
Your website is a vital extension of your brand, and I offer custom website design services tailored to your unique vision and requirements. Whether you need a professional corporate site, an interactive portfolio, or a robust e-commerce platform, I am dedicated to crafting a visually stunning and user-friendly website that resonates with your audience.
<h4><strong>E-Commerce Solutions</strong></h4>
In the rapidly growing online marketplace, having a robust e-commerce website is essential. I provide comprehensive e-commerce solutions, including secure payment gateways, intuitive navigation, and optimized product listings. My goal is to create an online shopping experience that is seamless and engaging for your customers.
<h4><strong>Responsive Web Development</strong></h4>
With the increasing prevalence of mobile device usage, it’s crucial for websites to be responsive. All websites I develop are fully responsive, ensuring they provide an optimal viewing experience across desktops, tablets, and smartphones. This adaptability helps retain visitors and enhances overall user engagement.
<h4><strong>SEO-Friendly Websites</strong></h4>
To succeed online, your website must be easily discoverable. I implement SEO best practices throughout the design process, optimizing your site’s content, images, and structure to improve its visibility on search engines. This strategic approach helps attract organic traffic and grow your online presence.
<h4><strong>Website Redesign</strong></h4>
If your existing website feels outdated or is not delivering the desired results, I offer website redesign services to refresh and enhance your online presence. My redesign process focuses on modern aesthetics, improved functionality, and enhanced user experience to align with your current business objectives.
<h4><strong>Content Management Systems (CMS)</strong></h4>
I utilize popular content management systems such as WordPress, allowing you to manage your website content effortlessly. With an intuitive interface, you can update text, images, and more independently, giving you full control over your site.
<h4><strong>Maintenance &amp; Support</strong></h4>
After launching your website, I provide ongoing maintenance and support services to keep everything running smoothly. This includes regular updates, troubleshooting, and assistance with any issues that may arise, ensuring your website remains functional and up-to-date.
<h4><strong>Digital Marketing &amp; SEO Services</strong></h4>
In addition to web design, I offer basic digital marketing services to help enhance your online visibility. Whether through social media marketing, email campaigns, or content strategies, I can help you effectively reach and engage your target audience.
<h3>Why Choose Me?</h3>
<ul>
  <li><strong>Experience</strong>: With over ten years of experience in web design and development, I am committed to delivering high-quality results tailored to your needs.</li>
  <li><strong>Client-Centric Approach</strong>: I prioritize understanding your vision and goals, ensuring that the final product exceeds your expectations.</li>
  <li><strong>Affordable Pricing</strong>: I provide competitive rates for quality services that fit your budget.</li>
  <li><strong>Timely Delivery</strong>: I am dedicated to delivering projects on time without compromising quality.</li>
</ul>
Let’s work together to create a stunning website that elevates your brand and connects with your audience. Contact me today for a consultation and a free quote!
<h2>Frequently Asked Questions</h2>

<h4>1. What services do you offer as a website designer?</h4>
<p>I provide a wide range of services, including custom website design, e-commerce solutions, responsive web development, SEO-friendly websites, website redesign, CMS implementation, ongoing maintenance and support, and digital marketing services.</p>

<h4>2. How long does it take to complete a website project?</h4>
<p>The timeline for completing a website project varies depending on its complexity and your specific needs. Typically, a basic website can take around 2-4 weeks, while more complex projects may require additional time. I will provide a detailed timeline during our initial consultation.</p>

<h4>3. Are your websites mobile-friendly?</h4>
<p>Yes! All websites I create are fully responsive, ensuring they deliver a seamless experience across all devices, including desktops, tablets, and smartphones. This responsiveness is essential for retaining visitors and enhancing user engagement.</p>

<h4>4. Can you assist with search engine optimization (SEO)?</h4>
<p>Absolutely! I integrate SEO best practices into the design process to enhance your website's visibility on search engines. This includes optimizing content, images, and site structure to help attract organic traffic.</p>

<h4>5. What kind of support do you offer after the website is launched?</h4>
<p>I provide ongoing maintenance and support services to ensure your website continues to function smoothly after launch. This includes routine updates, troubleshooting, and assistance with any issues that may arise.</p>

<h4>6. How can I update my website content after it's launched?</h4>
<p>If you opt for a Content Management System (CMS) like WordPress, you will have the ability to manage and update your website content independently. I will provide training on how to do this, and I'm available for further assistance as needed.</p>

<h4>7. What is the cost of your website design services?</h4>
<p>The cost of my website design services varies based on factors such as project complexity, required features, and your specific needs. I provide a free quote after discussing your project in detail to ensure it aligns with your budget.</p>

<h4>8. Can you redesign my existing website?</h4>
<p>Yes, I offer website redesign services to refresh and enhance your current site. This process can involve updating the layout, improving functionality, and optimizing for better performance and user experience.</p>

<h4>9. How do we start the project together?</h4>
<p>To get started, simply contact me through my website or reach out via email or phone. We’ll schedule a consultation to discuss your project goals, timeline, and budget, allowing us to create a customized plan for your website.</p>

<h4>10. Why should I choose you as my website designer in Ghaziabad?</h4>
<p>With over ten years of experience, a commitment to quality, and a client-focused approach, I am dedicated to helping you create a website that meets your needs and exceeds your expectations. Your satisfaction is my top priority!</p>

          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
