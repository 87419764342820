import {Helmet} from 'react-helmet';
import Contact from "../../Page/contact";



function App1() {
  return (
   <>
 <Helmet>
<title>Pankaj | Website Designer In Thane</title>
<meta name="description" content="Top website designer in Thane. Get a modern website design with me in low cost. Elevate your online presence today!"/>
<meta name="keywords" content="website designer in thane, web design company in thane, website design company in thane, website development company in thane, website development in thane, website developer thane, web design in thane, web development companies in thane, ecommerce development in thane, website developer near me"/>
<meta property="og:title" content="Pankaj | Website Designer In Thane" />
<meta property="og:description" content="Top Website Designer In Thane. Get a modern website design with me in low cost. Elevate your online presence today!" />
<meta property="og:site_name" content="Pankaj | Website Designer In Thane" />
<meta property="og:url" content="https://www.pankj.com/website-designer-in-thane"/>
<meta property="og:image" content="https://www.pankj.com/assets/img/dpnew.webp"/>
<meta name="twitter:image" content="https://www.pankj.com/assets/img/dpnew.webp" />
<meta name="twitter:title" content="Pankaj | Website Designer In Thane" />
<meta name="twitter:description" content="Top website designer in Thane. Get a modern website design with me in low cost. Elevate your online presence today!" />
<link rel="canonical" href="https://www.pankj.com/website-designer-in-thane" />
        </Helmet>
 <section id="hero" className="hero d-flex align-items-center fixedabove">
   <video
                className="mainsliderbg"
                autoPlay
                muted
                loop={true} style={{width:'100%'}}>
                <source src="assets/img/bg.mp4" type="video/mp4" />
                <source src="bg.ogg" type="video/ogg" />
  </video>
<div className="mainsliderbg">
     <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column">
            <img src="../assets/img/thyear.webp" className="imgsz" alt="Website Designer" />
  <h1 className="multiple_effects fzsml" id="text3d">Website Designer in Thane</h1>
          <div className="content_sec" data-aos="fade-up" data-aos-delay="400">Take a look at some of my works, and if you like them, feel free to contact me.</div>
  <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj" target="_blank" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>WhatsApp</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <div className="img-area">
      <div className="inner-area">
        <img src="../assets/img/pks2.webp" alt="Website Designer" />
      </div>
    </div>
        </div>
      </div>
    </div> 
</div>
  </section>   

    <main id="main">
  <section id="pageContent" className="pageContent">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Website Designer in Thane</h2>
        </header>

        <div className="row">
          <div className="col-lg-12">
Are you in search of a skilled website designer in Thane who can elevate your online presence? Look no further! I’m Pankaj, a professional website designer committed to creating exceptional, user-friendly websites that not only captivate visitors but also drive business growth.
<h3>Why Choose Pankaj for Your Website Design Needs?</h3>
<ol>
  <li><strong>Expertise in Crafting Quality Websites</strong>
With years of experience, I design websites that are not only visually appealing but also highly functional. Each site is tailored to meet the unique needs of your business, whether it’s an e-commerce platform, a corporate site, or a personal portfolio.</li>
  <li><strong>SEO-Friendly Designs for Enhanced Visibility</strong>
I understand the importance of search engine visibility. My websites are built with SEO best practices in mind, helping your business rank higher on Google and attract more organic traffic.</li>
  <li><strong>Comprehensive Range of Services</strong>
From initial concept to final launch, I offer a full suite of website design services. This includes website redesigns, e-commerce solutions, and ongoing maintenance. I work with popular CMS platforms like WordPress, allowing you to easily manage your website content post-launch.</li>
  <li><strong>Transparent Pricing and Personalized Support</strong>
I believe in transparent pricing and provide tailored quotes based on your specific requirements. My commitment to excellent customer service means I’m here to support you throughout the process and beyond, ensuring you have all the resources you need to succeed online.</li>
</ol>
<h3>Services Offered</h3>
<ul>
  <li><strong>Custom Website Design &amp; Redesign</strong>
Innovative, modern designs that reflect your brand's identity and enhance user experience, with options for redesigning existing sites.</li>
  <li><strong>E-commerce Website Development</strong>
Secure and efficient e-commerce websites designed to facilitate smooth transactions and maximize conversions.</li>
  <li><strong>Corporate and Portfolio Websites</strong>
Professionally designed sites that effectively showcase your business or personal projects, engaging visitors and communicating your message.</li>
  <li><strong>SEO and Digital Marketing Services</strong>
Basic SEO services to improve your site’s search engine ranking, with the option to collaborate with specialists for advanced strategies.</li>
</ul>
<h3>Get Started Today</h3>
Ready to transform your online presence? Contact me today to discuss your vision, and let’s work together to create a website that stands out in the digital landscape.
<h2>Frequently Asked Questions</h2>

<h4>What types of websites can Pankaj design in Thane?</h4>
<p>Pankaj specializes in designing a variety of websites, including corporate sites, e-commerce platforms, personal portfolios, and blogs. Each website is tailored to meet the unique needs and goals of the client.</p>

<h4>Why should I choose Pankaj as my website designer?</h4>
<p>Pankaj offers high-quality, responsive, and SEO-friendly websites that are crafted with attention to detail. With years of experience, he ensures that each project reflects the client's brand identity while providing a seamless user experience.</p>

<h4>Does Pankaj provide SEO services for my website?</h4>
<p>Yes, Pankaj incorporates basic SEO practices in all his website designs to enhance visibility on search engines. For advanced SEO strategies, he can recommend trusted partners to help further boost your site's performance.</p>

<h4>How much will it cost to design my website?</h4>
<p>The cost of designing a website varies based on several factors, including the complexity of the project, the number of pages, and any additional features you may want. Pankaj provides project-specific quotes after discussing your needs and budget.</p>

<h4>Can I update my website content after it’s launched?</h4>
<p>Absolutely! Pankaj designs websites using user-friendly CMS platforms like WordPress, allowing you to easily manage and update your content once the site is live. He also provides training and support if needed.</p>

<h4>What if I need help with my website after it’s live?</h4>
<p>Pankaj offers ongoing maintenance and support for all his clients. This includes troubleshooting, updates, and performance improvements to ensure your website remains secure and effective over time.</p>

<h4>How long does it take to complete a website design project?</h4>
<p>The timeline for completing a website design project depends on its complexity and the specific requirements involved. After discussing your project, Pankaj will provide a realistic timeline, which typically ranges from a few weeks to a couple of months.</p>

<h4>How can I get in touch with Pankaj for my website design project?</h4>
<p>You can contact Pankaj directly through the contact form on this website or by using the provided contact information. He will be happy to discuss your project and help you create a website that meets your needs.</p>


          </div>
        </div>
      </div>

    </section>
 <Contact />
    </main>
    </>
  );
}
export default App1;
